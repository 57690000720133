import React, { useState } from "react";
import ButtonAnswer from "./ButtonAnswer";
import Radios from "../Radios";

const Question = ({
  answer,
  onAnswer,
  numQuestion,
  exsam,
  questionId,
  answers,
  onAnswers,
  postData,
}) => {
  console.log(exsam);
  console.log(answers);
  const [answerText, setAnswerText] = useState("");
  // Function to update the answer based on questionId and, if applicable, choiceId
  const updateAnswer = (questionId, newAnswer, examType, choiceId) => {
    console.log("Updating answer:", questionId, newAnswer, examType);

    onAnswers((prevAnswers) => {
      console.log("Previous answers:", prevAnswers);

      const updatedAnswers = prevAnswers.map((answer) => {
        if (answer.questionId === questionId) {
          return {
            ...answer,
            answer: newAnswer,
            ...(examType !== "text-base" && { choiceId: choiceId }),
          };
        }
        return answer;
      });

      console.log("Updated answers:", updatedAnswers);

      return updatedAnswers;
    });
  };

  return (
    <div className="mt-5">
      <Header question={exsam[answer - 1]?.text} />
      {exsam[answer - 1]?.questionType === "TEXT_BASED" && (
        <AnswerText
          answers={answers}
          questionId={exsam[answer - 1]?.id}
          updateAnswer={updateAnswer}
          setAnswerText={setAnswerText}
          answerText={answerText}
          answer={answer}
        />
      )}
      {exsam[answer - 1]?.questionType === "MCQ" && (
        <AnswerMCQ
          choices={exsam[answer - 1]?.choices}
          answers={answers}
          questionId={exsam[answer - 1]?.id}
          updateAnswer={updateAnswer}
        />
      )}
      <ButtonAnswer
        answer={answer}
        onAnswer={onAnswer}
        numQuestion={numQuestion}
        answers={answers}
        postData={postData}
        setAnswerText={setAnswerText}
      />
    </div>
  );
};

export default Question;

const Header = ({ question }) => {
  return (
    <div>
      <div className="flex items-start  w-full px-1">
        <p className="text-[#1F2937] font-semibold lg:text-[22px]">Question</p>

        <p className="lg:text-[20px] text-[#374151] font-normal ml-[30px]">
          {question}
        </p>
      </div>
    </div>
  );
};

const AnswerText = ({
  questionId,
  updateAnswer,
  setAnswerText,
  answerText,
  answers,
  answer,
}) => {
  const handleUpdate = (e) => {
    setAnswerText(e);
    updateAnswer(questionId, e, "text-base");
  };
  return (
    <div className="mt-5 mb-5">
      <Input
        title={"Answer"}
        isValid={true}
        height={"min-h-[122px]"}
        onChange={handleUpdate}
        value={answers[answer - 1]?.answer}
      />
    </div>
  );
};
const AnswerMCQ = ({ choices, questionId, updateAnswer }) => {
  const [choosen, setChoosen] = useState("");
  console.log(choosen);
  const handleUpdate = (e) => {
    console.log(e);
    setChoosen(e);
    const choiceText = choices.find((choice) => choice.id == e);
    console.log(choiceText);
    updateAnswer(questionId, choiceText?.choiceText, "Mcq", e);
  };
  console.log(choices);
  return (
    <div className="mt-4">
      {choices &&
        choices.map((radio) => (
          <div className="flex items-center">
            <Radios
              value={radio?.id}
              label={radio?.choiceText}
              bg={radio?.correct}
              checked={radio?.id == choosen}
              onChange={handleUpdate}
            />
          </div>
        ))}
    </div>
  );
};

const Input = ({
  title,

  onChange,
  value,
  isValid,
  height,
  edit,
}) => {
  return (
    <div className="w-full">
      {/* <InputTitle title={title} /> */}
      <div
        className={`${height ? height : "h-[50px]"} ${
          isValid ? "border border-[#374151] " : "border-2 border-[#DD0303]"
        }  rounded-[4px] mt-1 bg-[#F5F5F5] flex items-start `}
      >
        <textarea
          className="w-full min-h-[120px] rounded-[4px] px-5 py-5 placeholder:text-[#909090] outline-none bg-[#F5F5F5]"
          placeholder={`Enter Your ${title}`}
          onChange={(e) => onChange?.(e.target.value)}
          value={value}
          id={title}
          disabled={edit}
        />
      </div>
    </div>
  );
};
