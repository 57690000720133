import { useState } from "react";
import Button from "../Button";
import { useParams } from "react-router-dom";
import axios from "../../api/axios";

const ButtonAnswer = ({
  answer,
  onAnswer,
  numQuestion,
  answers,
  postData,
  setAnswerText,
}) => {
  return (
    <div className="flex items-center justify-between w-full">
      <p className="text-[22px] text-[#374151] font-semibold hidden lg:flex gap-2">
        Answers <p>{answer}</p> of <p>{numQuestion}</p>
      </p>
      {((numQuestion) => 1) && answer === 1 && (
        <Button fristButton={"Next"} onSave={() => onAnswer((e) => e + 1)} />
      )}
      {answer > 1 && answer < numQuestion && (
        <Button
          fristButton={"Next"}
          secondButton={"Back"}
          onSave={() => onAnswer((e) => e + 1)}
          onCancel={() => onAnswer((e) => e - 1)}
        />
      )}
      {answer === numQuestion && (
        <Button
          fristButton={"Submit"}
          secondButton={"Back"}
          onSave={postData}
          onCancel={() => onAnswer((e) => e - 1)}
        />
      )}
    </div>
  );
};
export default ButtonAnswer;
