import { useState } from "react";
import { priceReg } from "../utils/regEx";
import { useEffect } from "react";

const usePriceValidation = () => {
  const [price, setPrice] = useState("");
  const [priceValid, setPriceValid] = useState(true);

  const validateTitle = (newTitle) => {
    const isValidTitle =
      !isNaN(newTitle) > 0 &&
      newTitle &&
      price !== "" &&
      priceReg.test(newTitle) &&
      newTitle.length > 0;
    setPriceValid(isValidTitle);
  };

  const handlePriceChange = (e) => {
    const newTitle = e;
    setPrice(newTitle);
    if (!price) {
      validateTitle(newTitle);
    }
  };
  useEffect(() => {
    if (!price || price === "") {
      setPriceValid(true);
    } else {
      validateTitle(price);
    }
  }, [price]);
  return [price, priceValid, setPriceValid, handlePriceChange, setPrice];
};
export default usePriceValidation;
