import React, { useState } from "react";
import ProfileInformation from "../../components/myProfile/ProfileInformation";
import EditProfile from "./EditProfile";

const ProfileDetails = ({
  firstName,
  lastName,
  firstNameEdit,
  firstNameValid,
  setFirstNameValid,
  handleFirstName,
  lastNameEdit,
  lastNameValid,
  setLastNameValid,
  handleLastName,
  updateUserPersonal,
  editProfile,
  setEditProfile,
  email,
  password,
  passwordValid,
  setPasswordValid,
  handlePassword,
}) => {
  // const [editProfile, setEditProfile] = useState(false);
  return (
    <div className="lg:absolute lg:top-[100%] lg:w-[60%] w-full mt-5">
      {editProfile ? (
        <EditProfile
          onEdit={setEditProfile}
          firstNameEdit={firstNameEdit}
          firstNameValid={firstNameValid}
          setFirstNameValid={setFirstNameValid}
          handleFirstName={handleFirstName}
          lastNameEdit={lastNameEdit}
          lastNameValid={lastNameValid}
          setLastNameValid={setLastNameValid}
          handleLastName={handleLastName}
          updateUserPersonal={updateUserPersonal}
          email={email}
          password={password}
          setPasswordValid={setPasswordValid}
          handlePassword={handlePassword}
          passwordValid={passwordValid}
        />
      ) : (
        <ProfileInformation
          onEdit={setEditProfile}
          firstName={firstName}
          lastName={lastName}
          email={email}
        />
      )}
    </div>
  );
};

export default ProfileDetails;
