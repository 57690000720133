import React from "react";
const Statistic = ({
  title,
  icon,
  num,
  width,
  height,
  fontSize,
  fontWight,
  fontColor,
}) => {
  return (
    <div
      className={`relative ${width} ${height} bg-white rounded-xl flex items-center gap-5  justify-center px-2 shadow-md`}
    >
      {icon}
      <div className={`text-[#1F618D]  font-bold ${fontSize} ${fontWight}`}>
        <p className={` ${fontColor ? fontColor : "text-[#1F618D]"}`}>
          {title}
        </p>
        <p className="text-[22px]">{num}</p>
      </div>
    </div>
  );
};

export default Statistic;
