// import CircularUnderLoad from "../CircularUnderLoad";

const Button = ({
  onCancle,
  onSave,
  isLoading,
  addAnother,
  onSaveAnother,
  editCard,
}) => {
  return (
    <div className="lg:mt-5 mt-8 flex items-center gap-5 flex-col lg:flex-row-reverse text-[24px] lg:text-base justify-start lg:border-t border-[#9CA3AF] pt-5 px-5">
      <button
        className="lg:w-[150px] w-full h-[50px] rounded-lg bg-[#0455BA] font-semibold text-white flex items-center justify-center gap-2"
        onClick={onSave}>
        Save
        {/* {isLoading && <CircularUnderLoad />} */}
      </button>
      {!editCard && addAnother && (
        <button
          className="lg:w-[200px] w-full h-[50px] rounded-lg border-[#0455BA] border font-semibold text-[#0455BA]"
          onClick={() => onSaveAnother?.()}>
          Save and add another
        </button>
      )}

      <button
        className="lg:w-[150px] w-full h-[50px] rounded-lg border-[#9CA3AF] border font-semibold text-[#374151]"
        onClick={() => onCancle?.(false)}>
        Cancel
      </button>
    </div>
  );
};

export default Button;
