import React, { useState } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import {
  convertDecimalToTime,
  detailsEsamStudent,
  formatTime,
} from "../../utils/main";

const StartExsam = ({ onStart, exsam }) => {
  return (
    <div className="pb-10">
      <Header />
      <div className="flex items-start flex-col gap-8 text-[22px] text-[#1F2937] font-semibold mt-8">
        <ExsamDetails detailslist={detailsEsamStudent} exsam={exsam} />
      </div>
      <StartButton onStart={onStart} />
    </div>
  );
};

export default StartExsam;

// const ExsamDetails = ({ title, value }) => {

const ExsamDetails = ({ detailslist, value, exsam }) => {
  return (
    <div className="flex items-start flex-col gap-8 lg:text-[22px] text-base text-[#1F2937] font-semibold lg:mt-10 mt-5 bg-[#E5E7EB] lg:bg-transparent px-3 py-5 lg:px-0 lg:py-0 rounded-xl">
      {detailslist.map((detail) => (
        <h2 className="flex items-start gap-1 " key={detail.title}>
          {detail.title}:
          <p className="lg:text-[20px] text-base font-normal  text-[#374151] ">
            {Array.isArray(detail.value) ? (
              <InstructionsList instructions={detail.value} />
            ) : (
              <p className="lg:text-[20px] text-base font-normal  text-[#374151] ">
                {detail.title === "Exsam Date"
                  ? exsam[detail.value]?.split("T")[0]
                  : detail.title === "Exsam Time"
                  ? formatTime(exsam[detail.value] ? exsam[detail.value] : null)
                  : detail.title === "Exsam Duration"
                  ? convertDecimalToTime(exsam[detail.value])
                  : exsam[detail.value]}
              </p>
            )}
          </p>
        </h2>
      ))}
    </div>
  );
};

const Header = () => {
  return (
    <div className="text-[#0455BA] flex items-center gap-3">
      <Link to={"/"}>
        <IoArrowBackCircleOutline className="lg:w-[56px] lg:h-[56px] w-[45px] h-[45px] " />
      </Link>
      <h2 className="font-bold lg:text-[40px] text-[30px]">Back to Home</h2>
    </div>
  );
};

const InstructionsList = ({ instructions }) => {
  return (
    <ul className="">
      {instructions.map((instruction, i) => (
        <li className="lg:text-[20px] text-[#374151] font-normal py-1" key={i}>
          {i + 1}-{instruction}
        </li>
      ))}
    </ul>
  );
};

const StartButton = ({ onStart }) => {
  return (
    <div
      className="w-full flex justify-center mt-8 cursor-pointer"
      onClick={() => onStart?.(true)}
    >
      <button
        className={`bg-[#40A726] rounded-md flex items-center lg:min-w-[450px] h-[60px] w-full lg:w-fit  px-4 gap-2 justify-center text-[22px] font-semibold`}
      >
        <h3 className="text-[#F5F5F5] font-semibold cursor-pointer">
          Start Now
        </h3>
      </button>
    </div>
  );
};
