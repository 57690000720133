import axios from "./axios";

export const getData = async (endPoint, onData) => {
  try {
    const res = await axios.get(endPoint);

    onData(res.data);
  } catch (err) {
    console.log(err);
  }
};
