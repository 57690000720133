import React from "react";
import login from "../assets/Mobile login-amico 1.png";
import SignUpForm from "../components/signUp/LoginForm";
const SingUp = () => {
  return (
    <div className="flex items-center justify-between h-screen px-10 gap-5">
      <Image />
      <SignUpForm />
    </div>
  );
};

export default SingUp;

const Image = () => {
  return (
    <div
      className="w-1/2 h-full 
    lg:flex items-center hidden"
    >
      <img src={login} alt="login" className="w-full h-full object-contain" />
    </div>
  );
};
