import React, { useEffect, useState } from "react";
import FormContainer from "../../containers/myProfile/FormContainer";
import Input from "./Input";
import { usePassValidation } from "../../hooks";
import Button from "./Button";
const Password = ({
  onEdit,
  password,
  passwordValid,
  setPasswordValid,
  handlePassword,
  updateUserPersonal,
}) => {
  const [passMatch, setPassMatch] = useState("");
  const [passMatchValid, setPassMatchValid] = useState(true);
  const [missMatch, setMissMatch] = useState("");
  useEffect(() => {
    if (passMatch) {
      if (passMatch === password) {
        setPassMatchValid(true);
        setMissMatch("");
      } else {
        setPassMatchValid(false);
        setMissMatch("Mismatching Password");
      }
    } else {
      setPassMatchValid(true);
      setMissMatch("");
    }
  }, [passMatch, password]);

  const handleSave = () => {
    if (!password) {
      setPasswordValid(false);
    }
    if (!passMatch) {
      setPassMatchValid(false);
    }
    if (password && passMatch && passwordValid) {
      updateUserPersonal();
    }
  };

  const handleCancel = () => {
    handlePassword("");
    setPassMatch("");
    onEdit?.(false);
  };
  return (
    <>
      <FormContainer>
        <Input
          title={"Password"}
          value={password}
          onChange={handlePassword}
          isValid={passwordValid}
          type={"password"}
        />
        <Input
          title={"Confirm Password"}
          value={passMatch}
          onChange={setPassMatch}
          isValid={passMatchValid}
          type={"password"}
          errorMessage={missMatch}
        />
      </FormContainer>
      <div className="mt-8">
        <Button onSave={handleSave} onCancel={handleCancel} />
      </div>
    </>
  );
};

export default Password;
