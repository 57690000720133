const AddButton = ({ onAdd, title }) => {
  return (
    <div
      className="w-full flex justify-end mt-5 cursor-pointer"
      onClick={() => onAdd?.(true)}
    >
      <button
        className={`bg-[#0455BA] rounded-md flex items-center h-[56px] w-full lg:w-fit  px-4 gap-2 justify-center text-[22px] font-semibold`}
      >
        <div className="bg-[#F5F5F5] rounded-md">
          <svg
            width="27"
            height="27"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 6C12.3183 6 12.6235 6.12643 12.8485 6.35147C13.0736 6.57652 13.2 6.88174 13.2 7.2V10.8H16.8C17.1183 10.8 17.4235 10.9264 17.6485 11.1515C17.8736 11.3765 18 11.6817 18 12C18 12.3183 17.8736 12.6235 17.6485 12.8485C17.4235 13.0736 17.1183 13.2 16.8 13.2H13.2V16.8C13.2 17.1183 13.0736 17.4235 12.8485 17.6485C12.6235 17.8736 12.3183 18 12 18C11.6817 18 11.3765 17.8736 11.1515 17.6485C10.9264 17.4235 10.8 17.1183 10.8 16.8V13.2H7.2C6.88174 13.2 6.57652 13.0736 6.35147 12.8485C6.12643 12.6235 6 12.3183 6 12C6 11.6817 6.12643 11.3765 6.35147 11.1515C6.57652 10.9264 6.88174 10.8 7.2 10.8H10.8V7.2C10.8 6.88174 10.9264 6.57652 11.1515 6.35147C11.3765 6.12643 11.6817 6 12 6Z"
              fill="#1E69CB"
            />
          </svg>
        </div>
        <h3 className="text-[#F5F5F5] font-semibold cursor-pointer">{title}</h3>
      </button>
    </div>
  );
};
export default AddButton;
