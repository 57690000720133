import React, { useEffect, useState } from "react";
import ButtonsDeleteAndEdit from "../ButtonsDeleteAndEdit";
import OverviewDeleteAndEdit from "../OverviewDeleteAndEdit";
import {
  convertDecimalToTime,
  detailsEsamTeacher,
  formatTime,
} from "../../utils/main";
import { useNavigate, useParams } from "react-router-dom";
import { getData } from "../../api/request";
import axios from "../../api/axios";

const Overview = ({ exsam, setChangeData }) => {
  return (
    <div className="mt-[100px]">
      <Exsam title={exsam?.title} exsam={exsam} onChangeData={setChangeData} />
      <ExsamDetails title={exsam?.title} exsam={exsam} />
    </div>
  );
};

export default Overview;

const Exsam = ({ title, exsam, onChangeData }) => {
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [editExsam, setEditExsam] = useState(false);
  const navigate = useNavigate();
  const HandleDelete = async (e) => {
    try {
      const response = await axios.delete(
        `/teacher-api/exams/${exsam?.examId}`
      );
      console.log(response);
      onChangeData((e) => !e);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <OverviewDeleteAndEdit
        deleteMessage={deleteMessage}
        onCancle={setDeleteMessage}
        editCard={editExsam}
        onEdit={setEditExsam}
        title={exsam?.title}
        startDate={exsam?.startDate}
        description={exsam?.description}
        duration={exsam?.duration}
        examId={exsam?.examId}
        publicEx={exsam?.public}
        onChangeData={onChangeData}
        HandleDelete={HandleDelete}
      />

      <div className="flex items-center justify-between w-full lg:h-[65px] border-[#374151] card__lessons cursor-pointer px-3 lg:border-b pb-4 flex-col lg:flex-row gap-2">
        <Title title={title} />
        <ButtonsDeleteAndEdit
          onDelete={setDeleteMessage}
          onEdit={setEditExsam}
          showMobile={true}
        />
      </div>
    </>
  );
};

const Title = ({ title }) => {
  return (
    <h2 className="text-[40px] font-bold  text-[#0455BA] w-full">{title}</h2>
  );
};

const ExsamDetails = ({ title, exsam }) => {
  return (
    <div className="flex items-start flex-col gap-8 lg:text-[22px] text-[#1F2937] font-semibold lg:mt-10 mt-5 bg-[#E5E7EB] lg:bg-transparent px-3 py-5 lg:px-0 lg:py-0 rounded-xl">
      {detailsEsamTeacher.map((detail) => (
        <h2 className="flex items-center gap-1">
          {detail.title}:
          <span className="lg:text-[20px] text-base font-normal  text-[#374151] ">
            {detail.title === "Exsam Date"
              ? exsam[detail.value]?.split("T")[0]
              : detail.title === "Exsam Time"
              ? formatTime(exsam[detail.value] ? exsam[detail.value] : null)
              : detail.title === "Exsam Duration"
              ? convertDecimalToTime(exsam[detail.value])
              : exsam[detail.value]}
          </span>
        </h2>
      ))}
    </div>
  );
};
