import { useState } from "react";
import { timeRegex } from "../utils/regEx";
import { useEffect } from "react";

const useTime = () => {
  const [price, setPrice] = useState("");
  const [priceValid, setPriceValid] = useState(true);

  const validateTitle = (newTitle) => {
    const isValidTitle = newTitle && timeRegex.test(newTitle);
    setPriceValid(isValidTitle);
    console.log("DurationValid");
  };

  const handlePriceChange = (e) => {
    const newTitle = e;
    setPrice(newTitle);
    if (!price) {
      validateTitle(newTitle);
    }
  };
  useEffect(() => {
    if (!price) {
      setPriceValid(true);
    } else {
      if (price) {
        validateTitle(price);
      }
    }
  }, [price]);
  return [price, priceValid, setPriceValid, handlePriceChange];
};
export default useTime;
