import { useRef, useState } from "react";
import profile from "../../assets/Messi y Argentina.jpeg";
import Overview from "../Overview";
import { useStateContext } from "../../context/CreateContext";
import { imageUrl } from "../../utils/main";

const CardPerson = ({
  firstName,
  lastName,
  idUser,
  setChangeData,
  name,
  file,
  setFile,
  updateUserPersonal,
  img,
}) => {
  const [uploadPhoto, setUploadPhoto] = useState(false);

  return (
    <>
      {uploadPhoto && (
        <UpdatedPhoto
          setUploadPhoto={setUploadPhoto}
          setChangeData={setChangeData}
          file={file}
          setFile={setFile}
          updateUserPersonal={updateUserPersonal}
        />
      )}
      <div className="lg:w-[400px] lg:h-[400px] w-full h-[280px] lg:absolute relative left-0 top-[60%]">
        <PersonImage
          setUploadPhoto={setUploadPhoto}
          image={img}
        />
      </div>
      <Title name={name} />
    </>
  );
};
export default CardPerson;

const PersonImage = ({ setUploadPhoto, image }) => {
  return (
    <div className={`w-full h-full`}>
      <img
        alt="Profile image"
        src={`${imageUrl}${image}?${new Date().getTime()}`}
        className="w-full h-full object-cover rounded-[10px] object-top"
      />
      <div
        className="absolute right-[0%] bottom-[0%] bg-white rounded-tl-lg rounded-br-lg w-[65px] h-[65px] flex items-center justify-center"
        onClick={() => setUploadPhoto?.(true)}>
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 12.5C8.67392 12.5 7.40215 13.0268 6.46447 13.9645C5.52678 14.9021 5 16.1739 5 17.5V37.5C5 38.8261 5.52678 40.0979 6.46447 41.0355C7.40215 41.9732 8.67392 42.5 10 42.5H40C41.3261 42.5 42.5978 41.9732 43.5355 41.0355C44.4732 40.0979 45 38.8261 45 37.5V17.5C45 16.1739 44.4732 14.9021 43.5355 13.9645C42.5978 13.0268 41.3261 12.5 40 12.5H36.035C35.372 12.4999 34.7362 12.2364 34.2675 11.7675L31.465 8.965C30.5275 8.02725 29.256 7.50028 27.93 7.5H22.07C20.744 7.50028 19.4725 8.02725 18.535 8.965L15.7325 11.7675C15.2638 12.2364 14.628 12.4999 13.965 12.5H10ZM25 35C25.9849 35 26.9602 34.806 27.8701 34.4291C28.7801 34.0522 29.6069 33.4997 30.3033 32.8033C30.9997 32.1069 31.5522 31.2801 31.9291 30.3701C32.306 29.4602 32.5 28.4849 32.5 27.5C32.5 26.5151 32.306 25.5398 31.9291 24.6299C31.5522 23.7199 30.9997 22.8931 30.3033 22.1967C29.6069 21.5003 28.7801 20.9478 27.8701 20.5709C26.9602 20.194 25.9849 20 25 20C23.0109 20 21.1032 20.7902 19.6967 22.1967C18.2902 23.6032 17.5 25.5109 17.5 27.5C17.5 29.4891 18.2902 31.3968 19.6967 32.8033C21.1032 34.2098 23.0109 35 25 35Z"
            fill="#374151"
          />
        </svg>
      </div>
    </div>
  );
};

const UpdatedPhoto = ({
  id,
  setUploadPhoto,
  setChangeData,
  file,
  setFile,
  updateUserPersonal,
}) => {
  const [valid, setValid] = useState(true);
  const uploadCard = useRef();
  const HandleUpload = (event) => {
    const file = event.target.files[0];
    // Check if a file is selected
    if (file) {
      // Check file type
      const fileType = file.type;
      if (
        fileType === "image/png" ||
        fileType === "image/jpeg" ||
        fileType === "image/jpg"
      ) {
        // Check file size (in bytes)
        const maxSize = 2 * 1024 * 1024; // 2MB
        if (file.size <= maxSize) {
          // File is valid
          setFile(file);
          setValid(true);
          updateUserPersonal(file, setUploadPhoto);
          // setUploadPhoto(false);
        } else {
          setValid(false);
        }
      } else {
        setValid(false);
      }
    }
  };
  const handleHidden = (e) => {
    setUploadPhoto(false);
  };
  return (
    <>
      <Overview onCancel={handleHidden} />
      <div
        className="lg:max-w-[400px] w-[90%] min-h-[171px] rounded-[10px] py-5 px-5  bg-white   flex-col flex justify-between items-center upload__img active "
        ref={uploadCard}>
        <div>
          <h2 className="text-3xl text-[#1F2937] font-bold border-b pb-2 text-center">
            Update profile Photo
          </h2>
          {valid ? (
            <p className={`text-[#6B7280] text-center pt-1`}>
              JPG or PNG no larger than 5 MB
            </p>
          ) : (
            <p className={`text-[#DD0303] text-center py-2 text-[14px]`}>
              invalid image! Please, make sure it is a valid image format jpg,
              jpeg or png (Maximum 5MB)
            </p>
          )}
        </div>
        <div className="flex items-center justify-center">
          <input
            type="file"
            id="upload"
            hidden
            onChange={(e) => HandleUpload(e)}
          />
          <label
            for="upload"
            className="w-[179px] h-[44px] rounded-[4px] bg-[#0455BA] text-white font-semibold flex items-center justify-center gap-1 cursor-pointer">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.5 11H13.5V5C13.5 4.447 13.052 4 12.5 4C11.948 4 11.5 4.447 11.5 5V11H5.5C4.948 11 4.5 11.447 4.5 12C4.5 12.553 4.948 13 5.5 13H11.5V19C11.5 19.553 11.948 20 12.5 20C13.052 20 13.5 19.553 13.5 19V13H19.5C20.052 13 20.5 12.553 20.5 12C20.5 11.447 20.052 11 19.5 11Z"
                fill="white"
              />

              <g mask="url(#mask0_2294_15588)"></g>
            </svg>
            Upload Photo
          </label>
        </div>
      </div>
    </>
  );
};
const Title = ({ name }) => {
  return (
    <h2 className="text-[48px] font-bold text-[#2D78DB] lg:hidden text-center w-full">
      {name}
    </h2>
  );
};
