import { useState, useEffect } from "react";
import { passwordRegex, titleRegex } from "../utils/regEx";

const usePassValidation = () => {
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);

  const validatePassword = (newPassword) => {
    // You can customize the validation logic here
    const isValidPassword = newPassword && passwordRegex.test(newPassword);
    setPasswordValid(isValidPassword);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  useEffect(() => {
    if (!password) {
      setPasswordValid(true);
    }
  }, [password]);

  return [password, passwordValid, setPasswordValid, handlePasswordChange];
};

export default usePassValidation;
