import React, { useEffect, useState } from "react";
import Submissions from "../../components/Submissions";
import {
  handelFilter,
  handleFilteSubmition,
  headerList,
  submissionHeaderList,
} from "../../utils/main";
import SubmissionCard from "../../components/SubmissionCard";
import PaginationRounded from "../../components/Paginate";
import SearchBar from "../../components/SearchBar";
import { useParams } from "react-router-dom";
import { getData } from "../../api/request";
import { handleFilteData } from "../../utils/main";
import FeedbackExsam from "../../components/takeExsam/FeedbackExsam";

const SubmissionsTeacher = ({ title }) => {
  const [submissions, setSubmissions] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterData, setFilterData] = useState(false);
  const [filteShow, setfilteShow] = useState(false);
  const [query, setQuery] = useState("");
  const [submissionsFilter, setSubmissionsFilter] = useState([]);
  const [feedback, setFeedback] = useState(false);
  const [studentName, setStudentName] = useState("");
  const { id } = useParams();
  useEffect(() => {
    getData(`/teacher-api/exams/${id}/submissions`, setSubmissions);
  }, [id]);
  console.log(submissions);
  useEffect(() => {
    const filterData = handleFilteSubmition(
      submissions,
      startDate,
      endDate,
      setfilteShow,
      setSubmissionsFilter,
      query
    );
    setSubmissionsFilter(filterData);
  }, [query, filterData]);
  console.log(submissionsFilter);
  return (
    <>
      {feedback ? (
        <FeedbackExsam
          submissionsId={feedback}
          title={title}
          onFeedback={setFeedback}
          studentName={studentName}
        />
      ) : (
        <div className="mt-20">
          <SearchBar
            placeHolder={"Search By Student Name"}
            onEndDate={setEndDate}
            onStartData={setStartDate}
            onFilterData={setFilterData}
            startDate={startDate}
            endDate={endDate}
            onSearch={setQuery}
          />
          <Submissions
            headerList={submissionHeaderList}
            length={submissions?.length}
          >
            <SubmissionsContainer
              dataList={headerList}
              submissions={submissions}
              submissionsFilter={submissionsFilter}
              filteShow={filteShow}
              onFeedback={setFeedback}
              onStudentName={setStudentName}
            />
          </Submissions>
        </div>
      )}
    </>
  );
};

export default SubmissionsTeacher;

const SubmissionsContainer = ({
  dataList,
  filteShow,
  changeData,
  submissionsFilter,
  submissions,
  onFeedback,
  onStudentName,
}) => {
  const [page, setPage] = useState(1);
  const { numberOfPages, dataForPage } = handelFilter(
    submissions,
    submissionsFilter,
    filteShow,
    page,
    6
  );
  useEffect(() => {
    if (dataForPage.length === 0 && submissions.length > 0) {
      setPage((e) => e - 1);
    }
  }, [changeData]);
  return (
    <div className="h-[520px]">
      <div className="mt-2 h-full max-h-[520px] flex flex-col justify-between">
        <div className="flex flex-col gap-4 mt-2">
          {dataForPage.length > 0 &&
            dataForPage.map((submission) => (
              <SubmissionCard
                data={dataList}
                submission={submission}
                onFeedback={onFeedback}
                submissionId={submission?.submissionId}
                onStudentName={onStudentName}
              />
            ))}
        </div>
      </div>{" "}
      {submissions.length > 0 && (
        <PaginationRounded
          count={numberOfPages}
          margin={"lg:mt-5 mt-5"}
          setPage={setPage}
        />
      )}
    </div>
  );
};
