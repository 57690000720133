import React, { useEffect, useState } from "react";
import Statistics from "../../containers/home/Statistics";
import SearchBar from "../../components/SearchBar";
import Exams from "../../containers/home/Exams";
import AddExsam from "../../containers/home/AddExsam";
import { getData } from "../../api/request";
import { handleFilteData } from "../../utils/main";

const Home = () => {
  const [isShow, setIsShow] = useState(false);
  const [changeData, setChangeData] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterData, setFilterData] = useState(false);
  const [exsams, setExsams] = useState([]);
  const [filteShow, setfilteShow] = useState(false);
  const [query, setQuery] = useState("");
  const [exsamsFilter, setExsamsFilter] = useState([]);

  useEffect(() => {
    getData("/teacher-api/exams", setExsams);
  }, [changeData]);
  useEffect(() => {
    const filterData = handleFilteData(
      exsams,
      startDate,
      endDate,
      setfilteShow,
      setExsamsFilter,
      query
    );
    setExsamsFilter(filterData);
  }, [query, filterData]);
  console.log(query);
  console.log(exsamsFilter);
  return (
    <div className="mt-8">
      {isShow && <AddExsam onShow={setIsShow} onChangeData={setChangeData} />}
      <Statistics />
      <SearchBar
        title={"Add New Exsam"}
        onShow={setIsShow}
        onSearch={setQuery}
        onEndDate={setEndDate}
        onStartData={setStartDate}
        onFilterData={setFilterData}
        startDate={startDate}
        endDate={endDate}
        placeHolder={"Search By Title Exsam"}
      />
      <Exams
        onChangeData={setChangeData}
        changeData={changeData}
        exsams={exsams}
        setExsams={setExsams}
        exsamsFilter={exsamsFilter}
        filteShow={filteShow}
      />
    </div>
  );
};

export default Home;
