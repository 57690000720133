import React from "react";
import { Link } from "react-router-dom";
import Form from "./Form";

const SignUpForm = () => {
  return (
    <div className="lg:w-1/2 w-full h-full flex items-center flex-col justify-center lg:px-[120px] pb-6">
      <>
        <div className="w-full flex flex-col items-center justify-center gap-5">
          <Header />
          <Form />

          <p className="text-sm font-semibold block ">
            Already have an account ?
            <Link to={"/login"} className="w-full">
              <span className="text-[#0455BA]">Log In</span>
            </Link>
          </p>
        </div>
      </>
    </div>
  );
};

export default SignUpForm;

const Header = () => {
  return (
    <div className="text-center">
      <h1 className="text-[36px] lg:text-[40px] font-bold text-[#0455BA]">
        Exam <span className="text-[#000000]">System</span>
      </h1>
      <h1 className="text-[20px] lg:text-[24px] font-semibold  text-[#374151]">
        Welcome to <span className="text-[#0455BA]">Exam</span> System
      </h1>
    </div>
  );
};
