import FormContainer from "../../containers/myProfile/FormContainer";
import { useNameInput } from "../../hooks";
import Button from "./Button";
import Input from "./Input";
import { HiMiniLockClosed } from "react-icons/hi2";

const Personal = ({
  onEdit,
  firstName,
  firstNameValid,
  setFirstNameValid,
  handleFirstName,
  lastName,
  lastNameValid,
  setLastNameValid,
  handleLastName,
  updateUserPersonal,
  email,
}) => {
  const handleSave = () => {
    if (!firstName) {
      setFirstNameValid(false);
    }
    if (!lastName) {
      setLastNameValid(false);
    }
    if (firstName && lastName) {
      updateUserPersonal();
    }
  };
  const handleCancel = () => {
    handleFirstName("");
    handleLastName("");
    onEdit(false);
  };
  console.log(lastName);
  return (
    <>
      <FormContainer>
        <Input
          title={"First Name"}
          value={firstName}
          onChange={handleFirstName}
          isValid={firstNameValid}
          setValid={setFirstNameValid}
        />
        <Input
          title={"Last Name"}
          value={lastName}
          onChange={handleLastName}
          isValid={lastNameValid}
        />
        <Input
          title={"Email"}
          value={email}
          isValid={true}
          edit={true}
          icon={<HiMiniLockClosed className="w-[20px] h-[20px]" />}
        />
      </FormContainer>
      <Button onSave={handleSave} onCancel={handleCancel} />
    </>
  );
};
export default Personal;
