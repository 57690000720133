import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import ErrorMessage from "../ErrorMessage";
import { typesExsam } from "../../utils/main";
import Input from "./Input";
import { priceReg } from "../../utils/regEx";

const Select = ({
  title,
  value,
  valid,
  onChange,
  onSimilarity,
  similarity,
  setSimilarityValid,
  similarityValid,
}) => {
  const [showSelect, setShowSelect] = useState(false);
  return (
    <div className="flex gap-5 relative w-full justify-between flex-col lg:flex-row">
      <p className="font-semibold text-[#374151] lg:w-[20%] w-full">{title}</p>
      <div className="flex lg:w-[80%] w-full justify-between gap-5 flex-col lg:flex-row">
        <div
          className="flex items-center justify-between w-full h-[50px] border  border-[#374151] rounded-[8px] px-2 bg-white mt-1 relative cursor-pointer "
          onClick={() => setShowSelect((e) => !e)}
        >
          <SelectValue value={value} title={title} />
          <SelectIcon showSelect={showSelect} onShow={setShowSelect} />
          <SelectList
            onChange={onChange}
            onShow={setShowSelect}
            showSelect={showSelect}
            list={typesExsam}
          />
        </div>
        <ErrorMessage isValid={valid} title={title} />
        {/* <Similatity
          value={similarity}
          onChange={onSimilarity}
          exsamType={value}
          valid={similarityValid}
          onValid={setSimilarityValid}
        /> */}
      </div>
    </div>
  );
};

export default Select;

const SelectValue = ({ value, title }) => {
  return (
    <>
      {value ? (
        <p className="text-sm text-[#374151] font-semibold">{value}</p>
      ) : (
        <p className="text-[#6B7280] text-sm">Choose your {title} </p>
      )}
    </>
  );
};

const SelectIcon = ({ onShow, showSelect }) => {
  return (
    <>
      {showSelect ? (
        <IoIosArrowUp
          className="w-[20px] h-[20px] cursor-pointer"
          onClick={() => onShow?.(true)}
        />
      ) : (
        <IoIosArrowDown
          className="w-[20px] h-[20px] cursor-pointer"
          onClick={() => onShow?.(false)}
        />
      )}
    </>
  );
};

const SelectList = ({ showSelect, onChange, onShow, list }) => {
  const handleSelect = (value) => {
    onChange?.(value);
    onShow?.(true);
  };
  return (
    <>
      {showSelect && (
        <div className="flex items-start justify-between flex-col rounded-bl-lg rounded-br-lg max-h-[150px] overflow-y-auto w-[calc(100%+2px)] border border-[#374151]  bg-white mt-1 absolute  z-20 right-[-1px] top-[80%]">
          {list?.map((data) => (
            <div
              className="flex items-center  gap-2 hover:bg-[#E5E7EB] w-full py-3 px-3 duration-100 transition-all cursor-pointer"
              onClick={() => handleSelect(data.value)}
            >
              {data?.icon}
              <p>{data.name}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const Similatity = ({ value, onChange, exsamType, valid, onValid }) => {
  const handleSimilatity = (e) => {
    if (Number(e) <= 100) {
      onChange?.(e);
    } else {
      if (!priceReg.test(value)) {
        onValid(false);
      }
    }
  };
  return (
    <div className="lg:w-1/2 w-full">
      {exsamType === "TEXT_BASED" && (
        <Input
          title={"Similatity"}
          placeHolder={"Enter your title"}
          value={value}
          onChange={handleSimilatity}
          valid={valid}
          errorMessage={"similarity must be [0%-100%]"}
          style={
            "flex w-full items-center lg:gap-8 lg:justify-between flex-col lg:flex-row"
          }
          inputWidth={"lg:w-[80%] w-full"}
        />
      )}
    </div>
  );
};
