import React from "react";

const Buttons = ({ onSave, onCancle }) => {
  return (
    <div className="flex items-center gap-3 justify-end flex-col-reverse lg:flex-row pr-5 lg:border-t sborder-[#37415175] pt-2 pb-2">
      <button
        onClick={() => {
          onCancle(false);
        }}
        className="text-2xl lg:text-[12px] lg:h-[30px] h-[40px] font-semibold w-full lg:w-fit border rounded-md lg:border-none border-[#9CA3AF]"
      >
        Cancel
      </button>
      <button
        className="lg:w-[70px] w-full lg:h-[30px] h-[40px] bg-[#0455BA] text-white rounded-md text-2xl lg:text-[12px] font-semibold"
        onClick={onSave}
      >
        ok
      </button>
    </div>
  );
};

export default Buttons;
