import { useEffect } from "react";

const Timer = ({ onTime, secondsRemaining, postData }) => {
  // const totalSeconds = secondsRemaining * 3600;
  const hours = Math.floor(secondsRemaining / 3600);
  const mins = Math.floor((secondsRemaining % 3600) / 60);
  const seconds = Math.floor(secondsRemaining % 60);
  useEffect(
    function () {
      if (secondsRemaining > 0) {
        secondsRemaining = setInterval(function () {
          onTime((e) => e - 1);
        }, 1000);
      } else {
        postData();
      }

      return () => clearInterval(secondsRemaining);
    },
    [secondsRemaining]
  );

  return (
    <div className="timer w-[100px] text-center">
      {hours < 10 && "0"}
      {hours}:{mins < 10 && "0"}
      {mins}:{seconds < 10 && "0"}
      {seconds}
    </div>
  );
};
export default Timer;
