import React from "react";

const FormContainer = ({ children }) => {
  return (
    <div className="w-full mt-5 bg-[#E5E7EB] lg:py-0 py-4 px-4 lg:px-0 rounded-[10px] lg:bg-[#F5F5F5] mb-3">
      <div className="flex justify-between w-full gap-10 flex-col">
        {children}
      </div>
    </div>
  );
};

export default FormContainer;
