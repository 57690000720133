import React from "react";

const Overview = ({ children, onCancel }) => {
  return (
    <div
      className="w-full h-full z-10 fixed top-0 left-0 overflow"
      onClick={(e) => onCancel?.(e)}
    >
      {children}
    </div>
  );
};

export default Overview;
