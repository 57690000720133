import { useState } from "react";
import { titleRegex } from "../utils/regEx";
import { useEffect } from "react";

const useTitleValidation = () => {
  const [title, setTitle] = useState("");
  const [titleValid, setTitleValid] = useState(true);

  const validateTitle = (newTitle) => {
    // You can customize the validation logic here
    const isValidTitle =
      newTitle &&
      titleRegex.test(newTitle) &&
      newTitle.length > 0 &&
      newTitle.length <= 100;
    setTitleValid(isValidTitle);
  };

  const handleTitleChange = (e) => {
    const newTitle = e;
    setTitle(newTitle);
    if (!title) {
      validateTitle(newTitle);
    }
  };
  useEffect(() => {
    if (!title) {
      setTitleValid(true);
    } else {
      if (
        title &&
        titleRegex.test(title) &&
        title.length > 0 &&
        title.length <= 100
      ) {
        setTitleValid(true);
        setTitle(title);
      } else {
        setTitleValid(false);
      }
    }
  }, [title]);

  return [title, titleValid, setTitleValid, handleTitleChange, setTitle];
};
export default useTitleValidation;
