import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { IoMenu } from "react-icons/io5";
import SideNav from "./SideNav";
import { useStateContext } from "../../context/CreateContext";
import { imageUrl } from "../../utils/main";
const Navbar = ({ navList }) => {
  const [activNav, setActivNav] = useState(false);
  return (
    <nav className="h-[100px] w-[100%] relative nav bg-white  border-[#374151]">
      <div className="lg:bg-white bg-[#f5f5f5] max-w-7xl mx-auto w-full h-full items-center justify-between flex lg:px-0 px-5">
        <Header />
        <Menu onActive={setActivNav} />
        <SideNav
          navActive={false}
          navList={navList}
          activNav={activNav}
          onActive={setActivNav}
        />
        <NavList navList={navList} />
        <Profile navList={navList} />
      </div>
    </nav>
  );
};

export default Navbar;

const Header = () => {
  return (
    <h2 className="text-[32px] font-bold text-[#1E69CB] hidden lg:block">
      Exam
      <span className="text-[#374151] ml-2">System</span>
    </h2>
  );
};

const Menu = ({ onActive }) => {
  const { pathname } = useLocation();
  return (
    <div className="lg:hidden text-[#1E69CB] flex items-center text-[28px] font-semibold gap-5">
      <IoMenu
        className="w-[44px] h-[44px] cursor-pointer"
        onClick={() => onActive?.(true)}
      />
      <p> {pathname === "/" ? "Home" : pathname.split("/")[1]}</p>
    </div>
  );
};
const NavList = ({ navList }) => {
  return (
    <div className="flex-1 ml-20 hidden lg:flex">
      <ul className="flex items-center text-[28px] font-semibold gap-10 flex-1">
        {navList?.map((link) => (
          <NavLinks
            title={link.title}
            to={link.to}
            key={link.to}
          />
        ))}
      </ul>
    </div>
  );
};

const NavLinks = ({ title, to }) => {
  return (
    <li>
      <NavLink
        className={({ isActive }) => (isActive ? "text-[#0455BA]" : "")}
        to={to}
        end>
        {title}
      </NavLink>
    </li>
  );
};

const Profile = () => {
  const { LogOut, role, img, email } = useStateContext();

  const teacherRole =
    role && role[0] === "ROLE_TEACHER" ? "/teacher" : "/student";

  return (
    <div className="flex items-center gap-5">
      <Link to={`profile`}>
        <div>
          <img
            src={`${imageUrl}${img}?${new Date().getTime()}`}
            alt="Profile Img"
            className="w-[56px] h-[56px] rounded-full"
          />
        </div>
      </Link>

      <FiLogOut
        className="w-[50px] h-[50px] text-[#374151] hidden lg:block cursor-pointer"
        onClick={LogOut}
      />
    </div>
  );
};
