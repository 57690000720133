import React, { useEffect, useState } from "react";
import CardPerson from "../../components/myProfile/CardPerson";
import ProfileDetails from "../../containers/myProfile/ProfileDetails";
import { useStateContext } from "../../context/CreateContext";
import { getData } from "../../api/request";
import axios from "../../api/axios";
import { useNameInput, usePassValidation } from "../../hooks";

const MyProfile = ({ role }) => {
  const { user, LogOut } = useStateContext();
  const [profile, setprofile] = useState("");
  const [editProfile, setEditProfile] = useState(false);
  const [changeData, setChangeData] = useState(false);
  const [firstNameEdit, firstNameValid, setfirstNameValid, handleFirstName] =
    useNameInput();
  const [lastNameEdit, lastNameValid, setLastNameValid, handleLastName] =
    useNameInput();
  const [password, passwordValid, setPasswordValid, handlePassword] =
    usePassValidation();
  const [file, setFile] = useState(null);

  useEffect(() => {
    getData(`/${role}/my-profile`, setprofile);
  }, [changeData]);

  const updateUserPersonal = async (file, setUploadPhoto) => {
    let bodyFormData = new FormData();
    bodyFormData.append(
      "firstName",
      firstNameEdit ? firstNameEdit : profile?.firstName
    );
    bodyFormData.append(
      "lastName",
      lastNameEdit ? lastNameEdit : profile?.lastName
    );
    password && bodyFormData.append("newPassword", password);
    file && bodyFormData.append("image", file, file?.name);

    try {
      const res = await axios.put(`/${role}/my-profile`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(res);
      password && LogOut();
      setChangeData((e) => !e);
      setEditProfile(false);
      setUploadPhoto(false);
    } catch (err) {
      console.log(err);
    }
  };

  console.log(file);
  return (
    <div className="w-full h-[40%] lg:bg-[#0455BA] lg:absolute left-0 flex items-end justify-center">
      <div className="max-w-7xl h-full w-full flex items-end justify-end relative lg:flex-row flex-col ">
        <Header
          name={user}
          firstName={profile?.firstName}
          lastName={profile?.lastName}
        />
        <CardPerson
          name={user}
          firstName={profile?.firstName}
          lastName={profile?.lastName}
          file={file}
          setFile={setFile}
          updateUserPersonal={updateUserPersonal}
          img={profile?.image}
        />
        <ProfileDetails
          firstName={profile?.firstName}
          lastName={profile?.lastName}
          firstNameEdit={firstNameEdit}
          firstNameValid={firstNameValid}
          setFirstNameValid={setfirstNameValid}
          handleFirstName={handleFirstName}
          lastNameEdit={lastNameEdit}
          lastNameValid={lastNameValid}
          setLastNameValid={setLastNameValid}
          handleLastName={handleLastName}
          updateUserPersonal={updateUserPersonal}
          editProfile={editProfile}
          setEditProfile={setEditProfile}
          email={profile?.email}
          password={password}
          setPasswordValid={setPasswordValid}
          handlePassword={handlePassword}
          passwordValid={passwordValid}
        />
      </div>
    </div>
  );
};

export default MyProfile;

const Header = ({ name, firstName, lastName }) => {
  return (
    <h2 className="text-[60px] font-semibold text-white hidden lg:block text-start w-[60%]">
      {firstName} {lastName}
    </h2>
  );
};
