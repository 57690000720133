import React, { useState } from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { handleDate } from "../../utils/main";
import dayjs from "dayjs";
const Calender = ({ onChange, onShow, min, max, value, onDateFilter }) => {
  const HandleChangeDate = (e) => {
    const formattedDate = handleDate(e);
    onChange(formattedDate);
    onDateFilter?.(formattedDate);
    onShow?.(false);
  };

  const minDate = min ? dayjs(min) : min;
  const maxDate = max ? dayjs(max) : max;
  return (
    <div className="flex items-end justify-center border border-[#9CA3AF] rounded-[4px] mb-2  flex-col overflow-hidden  pb-1 pt-1 font-semibold text-sm mt-4">
      <div className="flex items-center h-[300px] w-full justify-center">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateCalendar"]}>
            <DemoItem>
              <DateCalendar
                views={["year", "month", "day", "hours", "minutes"]}
                format="YYYY-MM-DD"
                onChange={HandleChangeDate}
                sx={{ width: "100%" }}
                minDate={minDate}
                maxDate={maxDate}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default Calender;
