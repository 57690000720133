import React, { useEffect, useRef, useState } from "react";
import image from "../assets/Ellipse 10.png";
import { RiDownloadFill } from "react-icons/ri";
import { HiDotsVertical } from "react-icons/hi";

const HistoryCard = ({ data, submission, onFeedback }) => {
  const [cardActive, setCardActive] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        // Clicked outside the card, so close it
        setCardActive(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="w-full h-[60px] lg:bg-[#E5E7EB] bg-white lg:shadow-none shadow-md rounded-xl flex items-center px-4 relative justify-between">
      <Info
        postion={data[0]?.postion}
        title={submission[data[0]?.value]}
        submission={submission}
        data={submission[data[1]?.value]?.split("T")[0]}
      />
      <InfoTitle
        postion={data[1]?.postion}
        title={submission[data[1]?.value]?.split("T")[0]}
      />
      <InfoTitle
        postion={data[2].postion}
        title={`${submission[data[2]?.value]}%`}
      />
      <Button onFeedback={onFeedback} submissionId={submission?.submissionId} />
      <h2 className="text-[22px] font-normal text-[#40A726] lg:hidden">
        {submission[data[2]?.value]}%
      </h2>
      <HiDotsVertical
        className="w-[24px] h-[24px] cursor-pointer text-[#0455BA] lg:hidden"
        onClick={(e) => {
          setCardActive((e) => !e);
          e.preventDefault();
        }}
      />
      <ManageCard cardActive={cardActive} />
    </div>
  );
};

export default HistoryCard;

const Info = ({ title, postion, data }) => {
  return (
    <InfoContiner postion={postion}>
      <Title title={title} />
      <div className="lg:hidden">
        <TitlePhone title={"Samantha Jenkins"} />
        <p className="text-xs text-[#1F2937] font-semibold pt-1">{data}</p>
      </div>
    </InfoContiner>
  );
};

const InfoContiner = ({ children, postion }) => {
  return (
    <div className={`${postion} lg:absolute h-full flex items-center gap-3`}>
      {children}
    </div>
  );
};
const InfoTitle = ({ title, postion }) => {
  return (
    <InfoContiner postion={postion}>
      <Title title={title} />
    </InfoContiner>
  );
};
const Title = ({ title }) => {
  return <h3 className="text-lg text-[#1F2937] hidden lg:block">{title}</h3>;
};
const TitlePhone = ({ title }) => {
  return <h3 className="text-base text-[#0455BA] font-semibold">{title}</h3>;
};
const Button = ({ onFeedback, submissionId }) => {
  return (
    <InfoContiner postion={"left-[85%]"}>
      <button
        className="lg:flex items-center  bg-[#0455BA] h-[34px] w-fit rounded-md text-white px-2 gap-2 hidden"
        onClick={() => onFeedback?.(submissionId)}
      >
        Feedback
      </button>
    </InfoContiner>
  );
};
const ManageCard = ({ onDelete, onEdit, cardActive }) => {
  return (
    <>
      {cardActive && (
        <div className="flex items-center justify-between gap-4 w-[159px] min-h-[40px] bg-[#E5E7EB] rounded-lg flex-col py-4 px-3 absolute top-14 right-0 z-10">
          <Buttons onDelete={onDelete} onEdit={onEdit} />
        </div>
      )}
    </>
  );
};
const Buttons = ({ onEdit, onDelete }) => {
  return (
    <div className="flex items-start gap-4 flex-col w-full">
      <button className=" rounded-md flex items-center justify-between w-full  gap-2 text-[#1F2937] font-bold text-xs">
        Feedback
        <RiDownloadFill className="w-[16px] h-[16px] text-[#0455BA]" />
      </button>
    </div>
  );
};
