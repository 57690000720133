import React from "react";

const OverviewCard = ({ children, title, onShow, width, styles }) => {
  return (
    <div
      className={`lg:max-h-[860px] lg:h-fit overflow-y-auto h-full ${
        width ? width : "lg:w-[760px]"
      }  py-6 rounded-[10px] overviewCard bg-white ${styles} z-50 w-full px-5 lg:px-0 `}>
      <Header
        title={title}
        onCancle={onShow}
      />
      {children}
    </div>
  );
};

export default OverviewCard;

const Header = ({ onCancle, title }) => {
  return (
    <>
      {title && (
        <div className=" items-center justify-between flex mb-2 lg:border-b border-[#9CA3AF] px-5 pb-3">
          <h2 className="text-[28px] font-semibold text-[#1E69CB]">{title}</h2>
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => onCancle?.(false)}
            className="cursor-pointer">
            <path
              d="M31.2999 28L41.3169 17.983C42.2293 17.0707 42.2293 15.596 41.3169 14.6837C40.4046 13.7713 38.9299 13.7713 38.0176 14.6837L28.0006 24.7007L17.9836 14.6837C17.0713 13.7713 15.5966 13.7713 14.6843 14.6837C13.7719 15.596 13.7719 17.0707 14.6843 17.983L24.7013 28L14.6843 38.017C13.7719 38.9293 13.7719 40.404 14.6843 41.3163C15.1393 41.7713 15.7366 42 16.3339 42C16.9313 42 17.5286 41.7713 17.9836 41.3163L28.0006 31.2993L38.0176 41.3163C38.4726 41.7713 39.0699 42 39.6673 42C40.2646 42 40.8619 41.7713 41.3169 41.3163C42.2293 40.404 42.2293 38.9293 41.3169 38.017L31.2999 28Z"
              fill="#374151"
            />

            <g mask="url(#mask0_1240_20873)"></g>
          </svg>
        </div>
      )}
    </>
  );
};
