import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomeStudent from "./pages/student/Home";
import HomeTeacher from "./pages/teacher/Home";
import Login from "./pages/Login";
import RequireAuth from "./RequireAuth";
import Layout from "./Layout";
import { MyProfile } from "./pages";
import { useStateContext } from "./context/CreateContext";
import History from "./pages/student/History";
import ExsamDetailsStudent from "./pages/student/ExsamDetails";
import ExsamDetails from "./pages/teacher/ExsamDetails";
import SingUp from "./pages/SingUp";

const App = () => {
  const { token, role } = useStateContext();

  const teacherRole =
    role && role[0] === "ROLE_TEACHER" ? "/teacher" : "/student";
  const loginRouteElement = token ? <Navigate to={"/"} /> : <Login />;
  const singUpRouteElement = token ? <Navigate to={"/"} /> : <SingUp />;
  const allowedRoles = {
    STUDENT: ["ROLE_STUDENT"],
    TEACHER: ["ROLE_TEACHER"],
  };
  return (
    <Routes>
      <Route path="/login" element={loginRouteElement} />
      <Route path="/singUp" element={singUpRouteElement} />
      {role?.find((role) => allowedRoles.STUDENT?.includes(role)) && (
        <Route element={<RequireAuth allowedRoles={["ROLE_STUDENT"]} />}>
          <Route path="/" element={<HomeStudent />} />
          <Route path="history" element={<History />} />
          <Route path="exsam/:id" element={<ExsamDetailsStudent />} />
          <Route path="profile" element={<MyProfile role={"student-api"} />} />
        </Route>
      )}

      {role?.find((role) => allowedRoles.TEACHER?.includes(role)) && (
        <Route element={<RequireAuth allowedRoles={["ROLE_TEACHER"]} />}>
          <Route path="/" element={<HomeTeacher />} />
          <Route path="exsam/:id" element={<ExsamDetails />} />
          <Route path="profile" element={<MyProfile role={"teacher-api"} />} />
        </Route>
      )}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;

const NotFound = () => {
  const { token } = useStateContext();

  if (!token) {
    return <Navigate to={"/login"} />;
  }
  return (
    <div className="flex items-center justify-center w-full h-screen flex-col">
      <h2 className="text-[250px]">
        4<span>0</span>4
      </h2>
      <h2 className="text-[50px]">Page Not Found</h2>
    </div>
  );
};
