import { FaFileLines } from "react-icons/fa6";
import { AiFillFileAdd } from "react-icons/ai";
import { BsFileEarmarkLock2Fill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import moment from "moment/moment";
import dayjs from "dayjs";
import { AiFillHome } from "react-icons/ai";
import { IoPerson } from "react-icons/io5";
import { PiTimerFill } from "react-icons/pi";
import { format, parse, parseISO } from "date-fns";
import { enUS } from "date-fns/locale";

export const navListTeacher = [
  {
    title: "Home",
    to: "/",
    icon: <AiFillHome className="w-[25px] h-[25px]" />,
  },

  {
    title: "My Profile",
    to: "profile",
    icon: <IoPerson className="w-[25px] h-[25px]" />,
  },
];
export const navListStudent = [
  {
    title: "Home",
    to: "/",
    icon: <AiFillHome className="w-[25px] h-[25px]" />,
  },
  {
    title: "History",
    to: "history",
    icon: <PiTimerFill className="w-[25px] h-[25px]" />,
  },
  {
    title: "My Profile",
    to: "profile",
    icon: <IoPerson className="w-[25px] h-[25px]" />,
  },
];

export const listStatistics = [
  {
    title: "Total Exsam",
    value: "40",
    icon: AiFillFileAdd,
  },
  {
    title: "public Exsam",
    value: "30",
    icon: FaFileLines,
  },
  {
    title: "Private Exsam",
    value: "10",
    icon: BsFileEarmarkLock2Fill,
  },
  {
    title: "Total Students",
    value: "10",
    icon: FaUsers,
  },
];
export const handleDate = (openDate) => {
  const momentDate = moment(openDate.$d);
  const formattedDate = momentDate.format("YYYY-MM-DD");
  return formattedDate;
};
export const handleTime = (input, isFormatting = true) => {
  if (isFormatting) {
    // Formatting the date to time string
    const momentDate = moment(input.$d);
    const formattedDate = momentDate.format("HH:mm:ss");
    return formattedDate;
  } else {
    // Reverse: Parsing the time string back to a date object
    const dummyDate = moment(
      "2000-01-01 " + input,
      "YYYY-MM-DD HH:mm:ss"
    ).toDate();
    return dummyDate;
  }
};

export const timeRanges = [
  {
    title: "Today",
    start: dayjs(),
    end: dayjs(),
  },
  {
    title: "Yesterday",
    start: dayjs().subtract(1, "day"),
    end: dayjs().subtract(1, "day"),
  },
  {
    title: "Last 3 Days",
    start: dayjs().subtract(3, "day"),
    end: dayjs(),
  },
  {
    title: "Last 7 Days",
    start: dayjs().subtract(7, "day"),
    end: dayjs(),
  },
  {
    title: "Last Month",
    start: dayjs().subtract(1, "month"),
    end: dayjs(),
  },
  {
    title: "All Time",
    start: null, // Set the appropriate start date or leave it as null
    end: dayjs(),
  },
];

export const headerList = [
  { title: "Student", postion: "left-[1%]" },
  { title: "Email", postion: "left-[22%]" },
  { title: "Submission Data", postion: "left-[45%]" },
  { title: "Score", postion: "left-[70%]" },
  { title: "Answers file", postion: "left-[85%]" },
];
export const submissionHeaderList = [
  { title: "Student ", postion: "left-[1%]" },
  { title: "Email", postion: "left-[22%]" },
  { title: "Submission Data", postion: "left-[45%]" },
  { title: "Score", postion: "left-[70%]" },
  { title: "Answers file", postion: "left-[85%]" },
];
export const histoyrHeaderList = [
  { title: "Exam Title", postion: "left-[1%]" },
  { title: "Date of Exam", postion: "left-[30%]" },
  { title: "Score", postion: "left-[60%]" },
  { title: "Answers file", postion: "left-[85%]" },
];
export const histoyrList = [
  { title: "Exam Title", postion: "left-[1%]", value: "examTitle" },
  { title: "Date of Exam", postion: "left-[30%]", value: "submissionDate" },
  { title: "Score", postion: "left-[60%]", value: "score" },
  { title: "Answers file", postion: "left-[85%]" },
];

export const headerListEditProfile = [
  "Personal information",
  // "Contact Information",
  "Change Password",
];
export const typesExsam = [
  { name: "MCQ", value: "MCQ" },
  { name: "Text-based", value: "TEXT_BASED" },
];
export const detailsEsam = [
  {
    title: "Exsam Title",
    value: "title",
  },
  {
    title: "Exsam Date",
    value: "startDate",
  },
  {
    title: "Exsam Duration",
    value: "duration",
  },
  {
    title: "Exsam Time",
    value: "startDate",
  },
];
export const detailsEsamStudent = [
  {
    title: "Exsam Title",
    value: "title",
  },
  {
    title: "Description",
    value: "description",
  },
  {
    title: "Exsam Date",
    value: "startDate",
  },
  {
    title: "Exsam Duration",
    value: "duration",
  },
  {
    title: "Exsam Time",
    value: "startDate",
  },
  // {
  //   title: "Number of Question",
  //   value: "12 Questions",
  // },
  {
    title: "Instructions",
    value: [
      " Lorem Ipsum is simply dummy text of the printing",
      " Lorem Ipsum is simply dummy text of the printing",
      " Lorem Ipsum is simply dummy text of the printing",
      " Lorem Ipsum is simply dummy text of the printing",
      " Lorem Ipsum is simply dummy text of the printing",
    ],
  },
];
export const detailsEsamTeacher = [
  {
    title: "Exsam Title",
    value: "title",
  },
  {
    title: "Description",
    value: "description",
  },
  {
    title: "Exsam Date",
    value: "startDate",
  },
  {
    title: "Exsam Duration",
    value: "duration",
  },
  {
    title: "Exsam Time",
    value: "startDate",
  },
];

export const handelFilter = (
  data,
  dataFilter,
  filteShow,
  page,
  elementPerPage
) => {
  const totalElements = filteShow ? dataFilter?.length : data?.length;
  console.log(totalElements);
  const elementsPerPage = elementPerPage;
  const numberOfPages = Math.ceil(totalElements / elementsPerPage);
  const startIndex = (page - 1) * elementsPerPage;
  const endIndex = startIndex + elementsPerPage;
  const dataForPage = filteShow
    ? dataFilter?.slice(startIndex, endIndex)
    : data?.slice(startIndex, endIndex);
  return { numberOfPages, dataForPage };
};

export const imageUrl =
  "https://elearning-media-bucket.s3.eu-central-1.amazonaws.com/ems/images/";

export function convertTimeToDecimal(timeString) {
  const [hours, minutes] = timeString.split(":");
  const decimalTime = parseInt(hours, 10) + parseInt(minutes, 10) / 60;
  return decimalTime.toFixed(2); // To round to two decimal places
}

export function convertDecimalToTime(decimalTime) {
  const hours = Math.floor(decimalTime);
  const minutes = Math.round((decimalTime % 1) * 60);

  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;
  return formattedTime;
}

export const formatTime = (timeString) => {
  if (timeString) {
    const parsedDate = parseISO(timeString);
    const formattedTime = format(parsedDate, "h:mm a");
    return formattedTime;
  }
};
export function formatTimeToComplexDate(timeString) {
  // Parse the time string into a Date object
  const parsedTime = parse(timeString, "HH:mm:ss", new Date());

  // Format the date using format function from date-fns
  const formattedDate = format(
    parsedTime,
    "M {$L: 'en', $u: undefined, $d: EEEE MMM dd y HH:mm:ss 'GMT'xxx}",
    {
      locale: enUS,
    }
  );

  return formattedDate;
}
function isValidTimeFormat(inputTime) {
  const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
  return timeRegex.test(inputTime);
}

export function convertTimeToCustomFormat(inputTime) {
  if (!isValidTimeFormat(inputTime)) {
    throw new Error("Invalid time format. Please use HH:mm:ss.");
  }

  const currentDate = new Date();
  const [hours, minutes, seconds] = inputTime.split(":");
  currentDate.setHours(hours, minutes, seconds);

  const formattedDate = {
    $L: "en",
    $u: undefined,
    $d: currentDate,
    $y: currentDate.getFullYear(),
    $M: currentDate.getMonth(),
    // Add other properties as needed
  };

  return formattedDate;
}

export const handleFilteData = (
  data,
  openDateSave,
  deadlinSave,
  setfilteShow,
  setFilterData,
  query,
  propertie
) => {
  let filterData = data?.filter((e) => {
    const titleMatches =
      !query ||
      e?.[propertie ? propertie : "title"]
        .toLowerCase()
        .includes(query.toLowerCase());
    // Check if lastUpdate is within the date range
    const lastUpdateDate = new Date(e?.startDate);
    // Check if openDateSave and deadlinSave are valid dates
    const startDate = openDateSave ? new Date(openDateSave) : null;
    const endDate = deadlinSave ? new Date(deadlinSave) : null;
    // Check if lastUpdateDate is within the date range (if startDate and endDate are valid)
    const lastUpdateInRange =
      startDate && endDate
        ? lastUpdateDate > startDate && lastUpdateDate < endDate
        : true;
    setfilteShow(true);
    return titleMatches && lastUpdateInRange;
  });
  return filterData;
};
export const handleFilteSubmition = (
  data,
  openDateSave,
  deadlinSave,
  setfilteShow,
  setFilterData,
  query
) => {
  let filterData = data.filter((data) => {
    const titleMatches =
      !query || data.studentName?.toLowerCase().includes(query.toLowerCase());
    // Check if lastUpdate is within the date range
    const lastUpdateDate = new Date(data.submissionDate);
    // Check if openDateSave and deadlinSave are valid dates
    const startDate = openDateSave ? new Date(openDateSave) : null;
    const endDate = deadlinSave ? new Date(deadlinSave) : null;
    // Check if lastUpdateDate is within the date range (if startDate and endDate are valid)
    const lastUpdateInRange =
      startDate && endDate
        ? lastUpdateDate > startDate && lastUpdateDate < endDate
        : true;
    setfilteShow(true);
    return titleMatches && lastUpdateInRange;
  });
  return filterData;
};
export function convertTimestamp(timestamp) {
  const currentDateTime = new Date();
  const currentYear = currentDateTime.getFullYear();
  const currentMonth = currentDateTime.getMonth();
  const currentDay = currentDateTime.getDate();

  const [hours, minutes, seconds] = timestamp.split(":");
  // Set the hours to 01 to represent 01:00:00
  const convertedDate = new Date(
    currentYear,
    currentMonth,
    currentDay,
    1,
    minutes,
    seconds
  );

  const resultObject = {
    $D: currentDay,
    $H: 1, // Set to 1 for 01:00:00
    $L: "en",
    $M: currentMonth,
    $W: currentDateTime.getDay(),
    $d: convertedDate,
    $isDayjsObject: true,
    $m: currentDateTime.getMinutes(),
    $ms: currentDateTime.getMilliseconds(),
    $s: currentDateTime.getSeconds(),
    $u: undefined,
    $x: {},
    $y: currentYear,
  };

  return resultObject;
}

// Example usage:

// Example usage:
const timestamp = "09:57:38";
const convertedObject = convertTimestamp(timestamp);
console.log(convertedObject);

// Example usage:
export const convertTimeToSeconds = (timeString) => {
  const [hoursStr, minutesStr] = timeString && timeString?.split(":");
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  return hours * 3600 + minutes * 60;
};
export function timeToSeconds(time) {
  if (typeof time !== "number" && typeof time !== "string") {
    return "Invalid input. Please provide a time as a string or a number in the format 'HH:MM' or 'H.MM'.";
  }

  // Convert time to a string and split into hours and minutes
  const timeString = typeof time === "number" ? time.toString() : time;
  const timeArray = timeString.split(".");

  if (timeArray.length !== 2) {
    return "Invalid time format. Please use 'HH:MM' or 'H.MM'.";
  }

  const [hours, minutes] = timeArray.map(Number);

  // Check if hours and minutes are valid
  if (isNaN(hours) || isNaN(minutes)) {
    return "Invalid time format. Please use 'HH:MM' or 'H.MM'.";
  }

  // Calculate total seconds
  const totalSeconds = hours * 3600 + minutes * 60;

  return totalSeconds;
}

// Example usage with a number:
const timeAsNumber = 10.2; // Assuming 10:20
console.log(`${timeAsNumber} is ${timeToSeconds(timeAsNumber)} seconds.`);

// Example usage:
const time1 = "10:20";
const time2 = "01:10";

console.log(`${time1} is ${timeToSeconds(time1)} seconds.`);
console.log(`${time2} is ${timeToSeconds(time2)} seconds.`);
