import React from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl } from "@mui/base";

const Radios = ({ label, value, bg, checked, onChange, chosen }) => {
  return (
    <FormControl>
      <FormControlLabel
        value={value}
        sx={{
          "& .MuiTypography-root": {
            fontSize: 14,
            fontWeight: 500,
          },
          "& .MuiButtonBase-root": {
            // color: bg ? "#40A726" : "#000",
            marginLeft: 0,
          },
          "&.css-l0gpaq-MuiButtonBase-root-MuiRadio-root .Mui-checked": {
            // color: bg ? "#40A726" : "#000",
          },
          "& .css-1xr217y-MuiFormControlLabel-root": {
            marginLeft: 0,
            marginRight: 0,
            // color: bg ? "#40A726" : "#DD0303",
          },
        }}
        control={
          <Radio
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 20,
                width: 26,
                height: 26,
                margin: 0,
              },
            }}
            checked={checked}
            onChange={(e) => onChange?.(e.target.value)}
          />
        }
        label={label}
      />
    </FormControl>
  );
};

export default Radios;
