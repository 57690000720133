import { useStateContext } from "../../context/CreateContext";
import InputTitle from "./InputTitle";

const ProfileInformation = ({ onEdit, firstName, lastName }) => {
  const { user, email } = useStateContext();
  return (
    <div className="flex flex-col justify-between gap-4">
      <Information title={"First Name"} value={firstName} />
      <Information title={"Last Name"} value={lastName} />
      <Information title={"Email"} value={email} />
      <Information title={"Password "} value={"***************"} />
      <button
        className="lg:w-[140px] w-full lg:h-[42px] h-[50px] bg-[#0455BA] rounded-[4px] text-white text-2xl lg:text-lg "
        onClick={() => {
          onEdit?.(true);
        }}
      >
        Update
      </button>
    </div>
  );
};

export default ProfileInformation;

const Information = ({ title, value, height, valid, type }) => {
  return (
    <div>
      <InputTitle title={title} />
      <div className="h-[50px] border-b border-[#374151] flex items-center pl-1">
        <p className="text-[#6B7280]">{value}</p>
      </div>
    </div>
  );
};
