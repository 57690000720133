import React from "react";
import Overview from "./Overview";
import OverviewCard from "./OverviewCard";
const DeleteMessage = ({ title, nameButton, bref, onDelete, onCancle }) => {
  return (
    <Overview>
      <OverviewCard width={"lg:max-w-[538px] max-w-[90%] max-h-[300px]"}>
        <div className="border-b border-[#E5E7EB] pb-4 px-5">
          <h3 className="text-[20px] text-[#374151] font-semibold">{title}</h3>
          <p className="text-[#374151 mt-2">{bref}</p>
        </div>

        <div className="font-semibold flex items-center gap-2 mt-3 lg:flex-row lg:justify-end flex-col-reverse w-full px-5">
          <button
            className="lg:w-[100px] w-full h-[40px] hover:bg-slate-100 rounded-md duration-100 border "
            onClick={(e) => {
              onCancle(false);
              e.preventDefault();
            }}
            id="DeleteMessage Cancel"
          >
            Cancel
          </button>
          <button
            className="lg:w-[100px] w-full h-[40px] text-white bg-[#DD0303] rounded-md"
            onClick={onDelete}
            id={"DeleteMessage" + nameButton}
          >
            {nameButton}
          </button>
        </div>
      </OverviewCard>
    </Overview>
  );
};

export default DeleteMessage;
