import Statistic from "../../components/Statistic";
import { listStatistics } from "../../utils/main";

const Statistics = ({}) => {
  return (
    <div className="lg:flex items-center justify-between mt-1 gap-5 grid grid-cols-2 grid-rows-2">
      {listStatistics.map((statistic, index) => (
        <Statistic
          key={index}
          width={"w-full"}
          height={"h-[138px]"}
          title={statistic.title}
          num={statistic.value}
          fontSize={"lg:text-[24px] md:text-lg text-base"}
          fontWight={"font-semibold"}
          icon={
            <statistic.icon className="w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20 xl:w-20 xl:h-20 text-[#1F618D]" />
          }
          fontColor={"text-[#1F618D]"}
        />
      ))}
    </div>
  );
};
export default Statistics;
