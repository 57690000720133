const Button = ({ onSave, onCancel }) => {
  return (
    <div className="flex items-center gap-4 flex-col lg:flex-row">
      <button
        className="w-full lg:w-[150px] h-[50px] lg:h-[56px] bg-[#0455BA] rounded-[8px] text-white text-lg "
        onClick={() => onSave?.()}
      >
        save
      </button>
      <button
        className="w-full h-[50px] lg:w-[150px] lg:h-[56px] border text-black rounded-[8px] border-[#9CA3AF] text-lg "
        onClick={() => onCancel?.(true)}
      >
        cancel
      </button>
    </div>
  );
};
export default Button;
