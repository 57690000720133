const listNav = ["Overview", "Questions", "Submissions"];
const Nav = ({ page, setPage }) => {
  return (
    <div className="absolute lg:border-t lg:border-b w-full left-0 border-[#0455BA] h-[60px] top-[100px]">
      <div className="max-w-7xl h-full w-full mx-auto flex items-center justify-between lg:justify-start relative lg:text-2xl text-xl text-[#0455BA] font-semibold lg:gap-14 cursor-pointer px-5 lg:px-0">
        {listNav.map((nav) => (
          <div
            className={`
              ${
                page === nav
                  ? "lg:bg-[#0455BA] lg:text-[#fff] duration-75 transition-all header__details"
                  : " text-[#2D78DB] lg:text-[#000]"
              } h-full px-2 flex items-center justify-center
            `}
            key={nav}
            onClick={() => setPage?.(nav)}
          >
            <h2>{nav}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Nav;
