import React, { useEffect, useRef, useState } from "react";
import image from "../assets/Ellipse 10.png";
import { RiDownloadFill } from "react-icons/ri";
import { HiDotsVertical } from "react-icons/hi";
import { imageUrl } from "../utils/main";

const SubmissionCard = ({
  data,
  submission,
  onFeedback,
  submissionId,
  onStudentName,
}) => {
  const [cardActive, setCardActive] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        // Clicked outside the card, so close it
        setCardActive(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div
      className="w-full lg:h-[60px] h-[70px] lg:bg-[#E5E7EB] bg-white rounded-xl flex items-center px-3 relative justify-between cursor-pointer"
      ref={cardRef}
    >
      <Info
        postion={data[0]?.postion}
        image={submission?.studentImage}
        name={submission?.studentName}
        data={submission?.submissionDate?.split("T")[0]}
        id={submission?.submissionId}
      />
      <InfoTitle postion={data[1]?.postion} title={submission?.studentEmail} />
      <InfoTitle
        postion={data[2].postion}
        title={submission?.submissionDate?.split("T")[0]}
      />
      <InfoTitle postion={"lg:left-[70%]"} title={submission?.score + "%"} />
      <Button
        onClick={() => {
          onFeedback?.(submissionId);
          onStudentName(submission?.studentName);
        }}
      />
      <h2 className="text-[22px] font-normal text-[#40A726] lg:hidden">
        {submission?.score + "%"}
      </h2>
      <HiDotsVertical
        className="w-[24px] h-[24px] cursor-pointer text-[#0455BA] lg:hidden"
        onClick={(e) => {
          setCardActive((e) => !e);
          e.preventDefault();
        }}
      />
      <ManageCard cardActive={cardActive} />
    </div>
  );
};

export default SubmissionCard;

const Info = ({ title, postion, image, name, data, id }) => {
  return (
    <InfoContiner postion={postion}>
      <img
        src={imageUrl + image}
        alt=""
        className="lg:w-[24px] lg:h-[24px] w-[30px] h-[30px] rounded-full"
      />
      <Title title={name} />
      <div className="lg:hidden">
        <TitlePhone title={name} />
        <p className="text-xs text-[#1F2937] font-semibold pt-1">
          <span className="pr-2">#{id}</span> {data}
        </p>
      </div>
    </InfoContiner>
  );
};

const InfoContiner = ({ children, postion }) => {
  return (
    <div className={`${postion} lg:absolute h-full flex items-center gap-3`}>
      {children}
    </div>
  );
};
const InfoTitle = ({ title, postion }) => {
  return (
    <InfoContiner postion={postion}>
      <Title title={title} />
    </InfoContiner>
  );
};
const Title = ({ title }) => {
  return <h3 className="text-lg text-[#1F2937] hidden lg:block">{title}</h3>;
};
const TitlePhone = ({ title }) => {
  return <h3 className="text-base text-[#0455BA] font-semibold">{title}</h3>;
};

const Button = ({ onClick }) => {
  return (
    <InfoContiner postion={"left-[85%]"}>
      <button
        className="lg:flex items-center  bg-[#0455BA] h-[34px] w-fit rounded-md text-white px-2 gap-2 hidden"
        onClick={onClick}
      >
        Feedback
      </button>
    </InfoContiner>
  );
};
const ManageCard = ({ onDelete, onEdit, cardActive }) => {
  return (
    <>
      {cardActive && (
        <div className="flex items-center justify-between gap-4 w-[159px] min-h-[40px] bg-[#E5E7EB] rounded-lg flex-col py-4 px-3 absolute top-14 right-0 z-10">
          <Buttons onDelete={onDelete} onEdit={onEdit} />
        </div>
      )}
    </>
  );
};
const Buttons = ({ onEdit, onDelete }) => {
  return (
    <div className="flex items-start gap-4 flex-col w-full">
      <button className=" rounded-md flex items-center justify-between w-full  gap-2 text-[#1F2937] font-bold text-xs">
        Download
        <RiDownloadFill className="w-[16px] h-[16px] text-[#0455BA]" />
      </button>
    </div>
  );
};
