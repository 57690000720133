import React, { useEffect, useState } from "react";
import Exsam from "../../components/Exsam";
import PaginationRounded from "../../components/Paginate";
import { getData } from "../../api/request";
import { handelFilter } from "../../utils/main";
import { useStateContext } from "../../context/CreateContext";

const Exams = ({
  changeData,
  onChangeData,
  exsams,
  setExsams,
  exsamsFilter,
  filteShow,
}) => {
  const [page, setPage] = useState(1);
  const { numberOfPages, dataForPage } = handelFilter(
    exsams,
    exsamsFilter,
    filteShow,
    page,
    5
  );
  useEffect(() => {
    if (dataForPage.length <= 0 && exsams.length > 0) {
      setPage((e) => e - 1);
    }
  }, [changeData]);

  return (
    <div className="h-[500px]">
      <div className="mt-1 h-full  flex flex-col justify-start">
        <h2 className="lg:text-[28px] font-semibold text-sm text-[#0455BA] lg:text-[#374151] lg:border-none border-b border-[#1E69CB] pb-2 mt-3 lg:pb-0 ">
          {filteShow ? exsamsFilter.length : exsams.length} Exams
        </h2>
        <div className="flex flex-col gap-8 mt-4">
          {dataForPage.length > 0 &&
            dataForPage.map((exsam) => (
              <Exsam
                title={exsam?.title}
                key={exsam.examId}
                startDate={exsam?.startDate}
                duration={exsam?.duration}
                description={exsam?.description}
                publicEx={exsam?.public}
                examId={exsam?.examId}
                onChangeData={onChangeData}
              />
            ))}
        </div>
      </div>
      {dataForPage.length > 0 && (
        <PaginationRounded count={numberOfPages} setPage={setPage} />
      )}
    </div>
  );
};

export default Exams;
