import React, { useState } from "react";
import Header from "../../components/myProfile/Header";
import Personal from "../../components/myProfile/Personal";
import Contact from "../../components/myProfile/Contact";
import Password from "../../components/myProfile/Password";

const EditProfile = ({
  onEdit,
  firstNameEdit,
  firstNameValid,
  setFirstNameValid,
  handleFirstName,
  lastNameEdit,
  lastNameValid,
  setLastNameValid,
  handleLastName,
  updateUserPersonal,
  email,
  password,
  passwordValid,
  setPasswordValid,
  handlePassword,
}) => {
  const [currentSection, setCurrenSection] = useState("Personal information");
  return (
    <div>
      <Header current={currentSection} onChange={setCurrenSection} />
      {currentSection === "Personal information" && (
        <Personal
          onEdit={onEdit}
          firstName={firstNameEdit}
          firstNameValid={firstNameValid}
          setFirstNameValid={setFirstNameValid}
          handleFirstName={handleFirstName}
          lastName={lastNameEdit}
          lastNameValid={lastNameValid}
          setLastNameValid={setLastNameValid}
          handleLastName={handleLastName}
          updateUserPersonal={updateUserPersonal}
          email={email}
        />
      )}
      {/* {currentSection === "Contact Information" && <Contact onEdit={onEdit} />} */}
      {currentSection === "Change Password" && (
        <Password
          onEdit={onEdit}
          password={password}
          setPasswordValid={setPasswordValid}
          handlePassword={handlePassword}
          passwordValid={passwordValid}
          updateUserPersonal={updateUserPersonal}
        />
      )}
    </div>
  );
};

export default EditProfile;
