import React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Radios from "../Radios";
import ErrorMessage from "../ErrorMessage";
const TypeExsam = ({ isValid, onChange, value, onValid }) => {
  const handleChange = (e) => {
    onChange(e);
    onValid(true);
  };
  return (
    <div className="flex items-center gap-5 relative">
      <p>Type of Exam?</p>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <Radios
            value={"Public"}
            label={"Public"}
            checked={value === "Public"}
            onChange={handleChange}
          />
          <Radios
            value={"Private"}
            label={"Private"}
            checked={value === "Private"}
            onChange={handleChange}
          />
        </RadioGroup>
      </FormControl>
      <ErrorMessage
        isValid={isValid}
        title={"Type of Exam"}
        style={"top-[75%]"}
      />
    </div>
  );
};

export default TypeExsam;
