import React, { useEffect, useState } from "react";
import StartExsam from "../../components/takeExsam/StartExsam";
import TakeExsam from "../../components/takeExsam/TakeExsam";
import { getData } from "../../api/request";
import { useParams } from "react-router-dom";

const ExsamDetails = () => {
  const convertData = (inputData) => {
    const result = [];

    inputData.forEach((item) => {
      if (item.questionType === "TEXT_BASED" && item.text) {
        result.push({
          questionId: item.id,
          answer: "",
        });
      } else if (
        item.questionType === "MCQ" &&
        item.choices &&
        Array.isArray(item.choices)
      ) {
        // Extract choice text and id

        result.push({
          questionId: item.id,
          answer: "",
          choiceId: 0,
        });
      }
    });

    return result;
  };

  const [startExsam, setstartExsam] = useState(false);
  const [exsam, setExsam] = useState([]);
  const [changeData, setChangeData] = useState(false);
  const { id } = useParams();
  const [answer, setAnswer] = useState([]);
  useEffect(() => {
    getData(`/student-api/exams/${id}`, setExsam);
  }, [changeData]);
  const [start, setStart] = useState("");
  const handleStartExsam = async () => {
    try {
      await getData(`/student-api/exams/${id}/start`, setStart);
      setstartExsam(true);
    } catch (err) {}
  };
  useEffect(() => {
    // Convert start data and set the answer state when start state is updated
    const convertedData = convertData(start ? start : []);
    setAnswer(convertedData);
  }, [start]);

  return (
    <div className="mt-5">
      {startExsam ? (
        <TakeExsam
          numQuestion={start?.length}
          exsam={exsam}
          start={start}
          answers={answer}
          onAnswers={setAnswer}
          duration={exsam?.duration}
        />
      ) : (
        <StartExsam
          onStart={handleStartExsam}
          exsam={exsam}
        />
      )}
    </div>
  );
};

export default ExsamDetails;
