import { useState } from "react";
import { emailRegex, titleRegex } from "../utils/regEx";
import { useEffect } from "react";

const useEmailValidation = () => {
  const [title, setTitle] = useState("");
  const [titleValid, setTitleValid] = useState(true);

  const validateTitle = (newTitle) => {
    // You can customize the validation logic here
    const isValidTitle = newTitle && emailRegex.test(newTitle);
    setTitleValid(isValidTitle);
  };

  const handleTitleChange = (e) => {
    const newTitle = e;
    setTitle(newTitle);
    validateTitle(newTitle);
  };
  useEffect(() => {
    if (!title) {
      setTitleValid(true);
    }
    if (title && emailRegex.test(title)) {
      setTitleValid(true);
    }
  }, [title]);
  return [title, titleValid, setTitleValid, handleTitleChange];
};
export default useEmailValidation;
