import { useState } from "react";
import Calender from "./Calender";
const Date = ({ onEndDate, onStartData, startDate, endDate }) => {
  const [toActive, setToActive] = useState(false);
  const [fromActive, setFromActive] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const HandelToActive = () => {
    setToActive(true);
    setFromActive(false);
  };
  const HandelFromActive = () => {
    setFromActive(true);
    setToActive(false);
  };

  return (
    <div className="px-4">
      <div className="flex items-center justify-between gap-4 mt-6 mb-2">
        <DateSection
          title={"From"}
          onActive={HandelFromActive}
          date={startDate}
        />
        <DateSection title={"To"} onActive={HandelToActive} date={endDate} />
      </div>
      {toActive && (
        <Calender
          onChange={setToDate}
          onDateFilter={onEndDate}
          onShow={setToActive}
          min={startDate}
          value={endDate}
        />
      )}
      {fromActive && (
        <Calender
          onChange={setFromDate}
          onDateFilter={onStartData}
          onShow={setFromActive}
          max={endDate}
          value={startDate}
        />
      )}
    </div>
  );
};
export default Date;

const DateSection = ({ title, date, isActive, onActive }) => {
  return (
    <div className="w-1/2">
      <div
        className="flex items-center justify-between h-[40px] border border-[#9CA3AF] rounded-[4px] px-2 cursor-pointer"
        onClick={() => onActive?.(true)}
      >
        <p className="text-sm text-[#374151] font-semibold">{title}</p>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.20002 2.39999C6.88176 2.39999 6.57654 2.52642 6.3515 2.75147C6.12645 2.97651 6.00002 3.28173 6.00002 3.59999V4.79999H4.80002C4.1635 4.79999 3.55306 5.05285 3.10297 5.50294C2.65288 5.95302 2.40002 6.56347 2.40002 7.19999V19.2C2.40002 19.8365 2.65288 20.447 3.10297 20.897C3.55306 21.3471 4.1635 21.6 4.80002 21.6H19.2C19.8365 21.6 20.447 21.3471 20.8971 20.897C21.3472 20.447 21.6 19.8365 21.6 19.2V7.19999C21.6 6.56347 21.3472 5.95302 20.8971 5.50294C20.447 5.05285 19.8365 4.79999 19.2 4.79999H18V3.59999C18 3.28173 17.8736 2.97651 17.6486 2.75147C17.4235 2.52642 17.1183 2.39999 16.8 2.39999C16.4818 2.39999 16.1765 2.52642 15.9515 2.75147C15.7265 2.97651 15.6 3.28173 15.6 3.59999V4.79999H8.40002V3.59999C8.40002 3.28173 8.2736 2.97651 8.04855 2.75147C7.82351 2.52642 7.51828 2.39999 7.20002 2.39999ZM7.20002 8.39999C6.88176 8.39999 6.57654 8.52642 6.3515 8.75147C6.12645 8.97651 6.00002 9.28173 6.00002 9.59999C6.00002 9.91825 6.12645 10.2235 6.3515 10.4485C6.57654 10.6736 6.88176 10.8 7.20002 10.8H16.8C17.1183 10.8 17.4235 10.6736 17.6486 10.4485C17.8736 10.2235 18 9.91825 18 9.59999C18 9.28173 17.8736 8.97651 17.6486 8.75147C17.4235 8.52642 17.1183 8.39999 16.8 8.39999H7.20002Z"
            fill="black"
          />
        </svg>
      </div>
      <p className="text-[10px] font-semibold text-[#374151] mt-2 ml-2">
        {date ? date?.split("T")[0] : "MM-DD-YYYY"}
      </p>
    </div>
  );
};
