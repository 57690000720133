import React, { useEffect, useState } from "react";
import Question from "./Question";
import ResultExsam from "./ResultExsam";
import FeedbackExsam from "./FeedbackExsam";
import {
  convertDecimalToTime,
  convertTimeToSeconds,
  formatTime,
  timeToSeconds,
} from "../../utils/main";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";
import { useStateContext } from "../../context/CreateContext";
import Timer from "./Timer";

const TakeExsam = ({
  numQuestion,
  exsam,
  start,
  answers,
  onAnswers,
  statDuration,
  duration,
}) => {
  let timer;
  const { user } = useStateContext();
  const [answer, setAnswer] = useState(1);
  const { id } = useParams();
  const navgiate = useNavigate();
  const [feedback, setFeedback] = useState(false);
  const [feedbackExsam, setFeedbackExsam] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(duration * 3600);

  const formatNumber = (num) => {
    if (isNaN(num)) {
      return "00";
    }
    return num < 10 ? `0${num}` : num.toString();
  };

  const formatTimeFeedback = () => {
    const totalExamTime = duration * 3600; // Replace with the total time allocated for the exam in seconds
    const timeSpent = totalExamTime - timeRemaining;
    const hours = Math.floor(timeSpent / 3600);
    const minutes = Math.floor((timeSpent % 3600) / 60);
    const seconds = timeSpent % 60;
    console.log(timeSpent, "spent");
    if (hours > 0) {
      return `${hours} Hours ${formatNumber(minutes)} Mins ${formatNumber(
        seconds
      )} Secs`;
    } else if (minutes > 0) {
      return `${formatNumber(minutes)} Mins ${formatNumber(seconds)} Secs`;
    } else {
      return ` ${formatNumber(seconds)} Secs`;
    }
  };
  const postData = async () => {
    try {
      const response = await axios.post(`/student-api/exams/${id}/submit`, {
        answers: answers,
      });
      setAnswer(numQuestion + 1);
      // Handle success or further actions here
      setFeedbackExsam(response.data);
      clearTimeout(timer);
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
    }
  };

  const handleFeedback = () => {
    navgiate("/");
  };
  return (
    <div>
      {answer <= numQuestion && (
        <div className=" bg-[#E5E7EB] lg:bg-[#F5F5F5] px-2 py-2 rounded-lg">
          <Header
            exsam={exsam}
            duration={timeRemaining}
            onTime={setTimeRemaining}
            timer={timer}
            postData={postData}
          />
          <Question
            answer={answer}
            onAnswer={setAnswer}
            numQuestion={numQuestion}
            exsam={start}
            answers={answers}
            onAnswers={onAnswers}
            postData={postData}
          />
        </div>
      )}
      {answer > numQuestion &&
        (feedback ? (
          <FeedbackExsam
            feedbackExsam={feedbackExsam}
            studentName={user}
            submissionsId={feedbackExsam?.submissionId}
            role="student"
            onFeedback={handleFeedback}
          />
        ) : (
          <ResultExsam
            onFeedback={setFeedback}
            feedbackExsam={feedbackExsam}
            duration={formatTimeFeedback}
            exsam={exsam}
          />
        ))}
    </div>
  );
};

export default TakeExsam;

const Header = ({ exsam, duration, onTime, postData }) => {
  const detailsEsam = [
    {
      title: "Exsam Title",
      value: "title",
    },
    {
      title: "Exsam Date",
      value: "startDate",
    },
  ];
  return (
    <div className="flex flex-col gap-5 border-b-2 border-[#9CA3AF] pb-5">
      {detailsEsam?.map((exs) => (
        <ExsamDetails
          key={exs.title}
          title={exs.title}
          value={exs.value}
          exsam={exsam}
        />
      ))}
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-20">
          <div className="flex items-center">
            <p className="text-[#1F2937] font-semibold lg:text-[22px]">at</p>
            <p className="lg:text-[20px] text-[#374151] font-normal ml-[115px]">
              {formatTime(exsam?.startDate)}
            </p>
          </div>

          <div className="flex items-center gap-3">
            <p className="text-[#1F2937] font-semibold lg:text-[22px]">For</p>
            <p className="lg:text-[20px] text-[#374151] font-normal ">
              {convertDecimalToTime(exsam?.duration)}
            </p>
          </div>
        </div>
        <div className="flex items-center ">
          <p className="text-[#1F2937] font-semibold lg:text-[22px]">
            duration:
          </p>
          <p className="lg:text-[20px] text-[#374151] font-normal ">
            <Timer
              secondsRemaining={duration}
              onTime={onTime}
              postData={postData}
              exsam={exsam}
            />
          </p>
        </div>
      </div>
    </div>
  );
};
const ExsamDetails = ({ title, value, exsam }) => {
  return (
    <div className="flex items-start gap-2 w-full px-1">
      <p className="text-[#1F2937] font-semibold lg:text-[22px]">{title}:</p>
      <p className="lg:text-[20px] text-[#374151] font-normal">
        {title === "Exsam Date" ? exsam[value]?.split("T")[0] : exsam[value]}
      </p>
    </div>
  );
};
