import ErrorMessage from "../ErrorMessage";

const Input = ({
  title,
  placeHolder,
  onChange,
  value,
  valid,
  heigth,
  style,
  inputWidth,
  errorMessage,
}) => {
  console.log(valid, title);

  return (
    <div className={style}>
      <p className="font-semibold text-[#374151] lg:w-[20%] w-full text-start">
        {title}
      </p>
      <div
        className={`${
          heigth ? heigth : "h-[50px]"
        }  border border-[#374151] rounded-[8px] mt-1 relative ${
          inputWidth ? inputWidth : "w-full"
        } `}>
        <input
          type="text"
          className="w-full h-full rounded-[8px]  px-2 placeholder:text-[#6B7280] outline-none placeholder:align-text-top placeholder-start"
          placeholder={placeHolder}
          onChange={(e) => onChange?.(e.target.value)}
          value={value}
        />
        <ErrorMessage
          isValid={valid}
          title={title}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );
};
export default Input;
