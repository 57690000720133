import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import ExsamGrid from "../../containers/homeStudent/ExsamGrid";
import PaginationRounded from "../../components/Paginate";
import { getData } from "../../api/request";
import { handelFilter, handleFilteData } from "../../utils/main";

const Home = () => {
  const [isShow, setIsShow] = useState(false);
  const [changeData, setChangeData] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterData, setFilterData] = useState(false);
  const [exsams, setExsams] = useState([]);
  const [filteShow, setfilteShow] = useState(false);
  const [query, setQuery] = useState("");
  const [exsamsFilter, setExsamsFilter] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    getData(`/student-api/exams`, setExsams);
  }, [changeData, page]);
  useEffect(() => {
    const filterData = handleFilteData(
      exsams.content,
      startDate,
      endDate,
      setfilteShow,
      setExsamsFilter,
      query
    );
    setExsamsFilter(filterData);
  }, [query, filterData, exsams]);

  const { numberOfPages, dataForPage } = handelFilter(
    exsams?.content,
    exsamsFilter,
    filteShow,
    page,
    10
  );
  return (
    <div>
      <SearchBar
        onShow={setIsShow}
        onSearch={setQuery}
        onEndDate={setEndDate}
        onStartData={setStartDate}
        onFilterData={setFilterData}
        startDate={startDate}
        endDate={endDate}
        placeHolder={"Search By Title Exsam"}
      />
      <div className="lg:mt-5">
        {dataForPage?.length > 0 && <ExsamGrid exsams={dataForPage} />}
        {dataForPage?.length > 0 && (
          <PaginationRounded
            count={numberOfPages}
            margin={"lg:mt-0 mt-5"}
            setPage={setPage}
          />
        )}
      </div>
    </div>
  );
};

export default Home;
