import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useStateContext } from "./context/CreateContext";
import { ToastContainer } from "react-toastify";
import Navbar from "./containers/navbar/Navbar";
import { navListStudent, navListTeacher } from "./utils/main";
import { jwtDecode } from "jwt-decode";
// import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
  const { role, token } = useStateContext();
  const location = useLocation();

  console.log(role);
  let decode;
  if (token) {
    decode = jwtDecode(token);
  }
  console.log(decode);

  return token ? (
    <>
      <ToastContainer />
      <Navbar
        navList={role[0] === "ROLE_STUDENT" ? navListStudent : navListTeacher}
      />
      <div className="max-w-7xl mx-auto px-5 lg:px-0">
        <Outlet />
      </div>
    </>
  ) : (
    <Navigate
      to="/login"
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
