import React, { useEffect, useState } from "react";
import { IoMdShare } from "react-icons/io";
import QuestionsCard from "./QuestionsCard";
import DeleteMessage from "../DeleteMessage";
import ShareLink from "./ShareLink";
import AddQuestion from "../../containers/exsamManage/AddQuestion";
import AddButton from "../AddButton";
import QuestionsTextCard from "./QuestionsTextCard";
import { getData } from "../../api/request";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";

const Questions = ({ title, examId }) => {
  const [addQuestion, setAddQuestion] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [changeData, setChangeData] = useState(false);
  const [shareLink, setShareLink] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getData(`/teacher-api/exams/${id}/questions`, setQuestions);
  }, [changeData, id]);
  console.log(questions, "..................................");
  return (
    <>
      {addQuestion && (
        <AddQuestion
          title={"Add New Question"}
          onShow={setAddQuestion}
          onChangeData={setChangeData}
          id={id}
          questionsNumber={questions?.length}
          question={questions}
        />
      )}
      <div className="mt-[100px]">
        <Exsam
          title={title}
          shareLink={shareLink}
          setShareLink={setShareLink}
          examId={examId}
          onChangeData={setChangeData}
        />
        <AddButton
          onAdd={setAddQuestion}
          title={"Add Question"}
        />

        {questions.length > 0 &&
          questions.map((question) => (
            <>
              {question.questionType === "TEXT_BASED" && (
                <QuestionsTextCard
                  key={question.id}
                  questioniId={question.id}
                  answer={question?.text}
                  modelAnswer={question?.modelAnswer}
                  test={question?.modelAnswer}
                  question={question}
                  similarity={question?.similarity}
                  onChangeData={setChangeData}
                  questionType={question?.questionType}
                />
              )}
              {question.questionType === "MCQ" && (
                <QuestionsCard
                  key={question.id}
                  questioniId={question.id}
                  choices={question?.choices}
                  answer={question?.text}
                  modelAnswer={question?.modelAnswer}
                  question={question}
                  onChangeData={setChangeData}
                  questionType={question?.questionType}
                />
              )}
            </>
          ))}

        {/* <SaveButton onSave={setShareLink} /> */}
      </div>
    </>
  );
};

export default Questions;

const Exsam = ({ title, shareLink, setShareLink, examId, onChangeData }) => {
  const [deleteMessage, setDeleteMessage] = useState(false);
  const navigate = useNavigate();
  const HandleDelete = async (e) => {
    try {
      const response = await axios.delete(`/teacher-api/exams/${examId}`);
      console.log(response);
      onChangeData((e) => !e);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {deleteMessage && (
        <DeleteMessage
          title={"Are you sure about to delete this Exsam"}
          bref={"If you delete those questions  you them again"}
          nameButton={"Yes"}
          onCancle={setDeleteMessage}
          onDelete={HandleDelete}
        />
      )}
      {shareLink && <ShareLink onShow={setShareLink} />}

      <div className="flex items-center w-full justify-between lg:h-[65px] border-[#374151] card__lessons  cursor-pointer lg:px-3 border-b pb-4 flex-col lg:flex-row">
        <Title title={title} />
        <Buttons
          onDelete={setDeleteMessage}
          onShare={setShareLink}
        />
      </div>
    </>
  );
};

const Title = ({ title }) => {
  return <h2 className="text-[40px] font-bold  text-[#0455BA]">{title}</h2>;
};

const Buttons = ({ onShare, onDelete }) => {
  return (
    <div className="flex items-center gap-4 flex-col lg:flex-row justify-end lg:w-[30%] w-full">
      <button
        className="lg:max-w-[150px] lg:w-fit w-full lg:h-[44px] h-[49px]  rounded-md border px-2 bg-[#0455BA] flex items-center justify-center gap-1 font-semibold text-lg text-[#fff]"
        onClick={() => onShare?.(true)}>
        <IoMdShare className="w-[24px] h-[24px]" />
        Share link
      </button>

      <button
        className="lg:max-w-[150px] w-full rounded-md lg:h-[44px] h-[49px] px-2 bg-[#DD0303] flex items-center justify-center text-lg text-white font-semibold gap-1"
        onClick={() => onDelete?.(true)}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.666 3.49999H12.2493C12.5702 3.49999 12.8327 3.76249 12.8327 4.08332C12.8327 4.40416 12.5702 4.66666 12.2493 4.66666H11.666V11.0833C11.666 12.0482 10.8808 12.8333 9.91602 12.8333H4.08268C3.11785 12.8333 2.33268 12.0482 2.33268 11.0833V4.66666H1.74935C1.42852 4.66666 1.16602 4.40416 1.16602 4.08332C1.16602 3.76249 1.42852 3.49999 1.74935 3.49999H2.33268H4.66602V2.52466C4.66602 1.77566 5.31993 1.16666 6.12435 1.16666H7.87435C8.67877 1.16666 9.33268 1.77566 9.33268 2.52466V3.49999H11.666ZM6.12435 2.33332C5.95752 2.33332 5.83268 2.43424 5.83268 2.52466V3.49999H8.16602V2.52466C8.16602 2.43424 8.04118 2.33332 7.87435 2.33332H6.12435ZM8.74935 9.91666C9.07018 9.91666 9.33268 9.65416 9.33268 9.33332V6.99999C9.33268 6.67916 9.07018 6.41666 8.74935 6.41666C8.42852 6.41666 8.16602 6.67916 8.16602 6.99999V9.33332C8.16602 9.65416 8.42852 9.91666 8.74935 9.91666ZM5.83268 9.33332C5.83268 9.65416 5.57018 9.91666 5.24935 9.91666C4.92852 9.91666 4.66602 9.65416 4.66602 9.33332V6.99999C4.66602 6.67916 4.92852 6.41666 5.24935 6.41666C5.57018 6.41666 5.83268 6.67916 5.83268 6.99999V9.33332Z"
            fill="white"
          />
        </svg>
        Delete
      </button>
    </div>
  );
};

const SaveButton = ({ onSave }) => {
  return (
    <div className="flex justify-end mt-5">
      <button
        className="lg:min-w-[120px] lg:w-fit w-full lg:h-[44px] h-[49px] rounded-md border px-2 bg-[#0455BA] flex items-center justify-center gap-1 font-semibold text-lg text-[#fff]"
        onClick={() => onSave?.(true)}>
        Save
      </button>
    </div>
  );
};
