import React, { useEffect, useState } from "react";
import Nav from "../../components/exsamManage/Nav";
import Overview from "../../components/exsamManage/Overview";
import Questions from "../../components/exsamManage/Questions";
import SubmissionsTeacher from "./Submissions";
import { getData } from "../../api/request";
import { useParams } from "react-router-dom";

const ExsamDetails = () => {
  const [page, setPage] = useState("Overview");
  const [exsam, setExsam] = useState([]);
  const [changeData, setChangeData] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    getData(`/teacher-api/exams/${id}`, setExsam);
  }, [changeData]);
  console.log(exsam);
  return (
    <div>
      <Nav setPage={setPage} page={page} />
      {page === "Overview" && (
        <Overview exsam={exsam} setChangeData={setChangeData} />
      )}
      {page === "Questions" && (
        <Questions title={exsam?.title} examId={exsam?.examId} />
      )}
      {page === "Submissions" && (
        <SubmissionsTeacher title={exsam?.title} examId={exsam?.examId} />
      )}
    </div>
  );
};

export default ExsamDetails;
