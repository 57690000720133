import React from "react";
import LoginForm from "../components/login/LoginForm";
import login from "../assets/Mobile login-amico 1.png";
const Login = () => {
  return (
    <div className="flex items-center justify-between h-screen px-10 gap-5">
      <Image />
      <LoginForm />
    </div>
  );
};

export default Login;

const Image = () => {
  return (
    <div
      className="w-1/2 h-full 
    lg:flex items-center hidden"
    >
      <img src={login} alt="login" className="w-full h-full object-contain" />
    </div>
  );
};
