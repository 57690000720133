import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import ErrorMessage from "../ErrorMessage";
import dayjs from "dayjs";

const TimeInput = ({ onChange, isValid, title, onValid, value }) => {
  const HandleChangeTime = (time) => {
    onChange(time);
    console.log(time);
    onValid(true);
  };

  return (
    <div className="relative">
      <p className="font-semibold text-[#374151]">{title}</p>
      <Time
        onDate={HandleChangeTime}
        value={value}
      />
      <ErrorMessage
        isValid={isValid}
        title={title}
      />
    </div>
  );
};

export default TimeInput;

const Time = ({ onDate, value }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["TimePicker"]}>
        {value ? (
          <TimePicker
            className="bg-[#fff] outline-none focus:border-none hover:border-none w-full border-none"
            onChange={(e) => onDate?.(e)}
            format="hh:mm:ss"
            value={value && dayjs(value)}
          />
        ) : (
          <TimePicker
            className="bg-[#fff] outline-none focus:border-none hover:border-none w-full border-none"
            onChange={(e) => {
              if (e !== null) onDate?.(e);
            }}
            format="hh:mm:ss"
            // value={value && dayjs(value)}
          />
        )}
      </DemoContainer>
    </LocalizationProvider>
  );
};
