import { useEffect } from "react";
import { useState } from "react";

const useNameInput = () => {
  const [name, setName] = useState("");
  const [isValid, setValid] = useState(true);

  const validateName = (input) => {
    // Regular expression for a simple name validation (letters only, at least 2 characters)
    const nameRegex = /^[a-zA-Z]{1,}$/;
    return nameRegex.test(input);
  };

  const handleNameChange = (e) => {
    const newName = e;
    setName(newName);
    setValid(validateName(newName));
  };

  return [name, isValid, setValid, handleNameChange];
};

export default useNameInput;
