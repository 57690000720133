import { headerListEditProfile } from "../../utils/main";

const Header = ({ current, onChange }) => {
  return (
    <div className="flex items-center justify-between lg:justify-start gap-5 lg:gap-10">
      {headerListEditProfile?.map((section) => (
        <p
          className={`text-lg md:text-[20px] font-semibold text-center  ${
            section === current
              ? "text-[#0455BA] header__profile"
              : "text-[#6B7280] cursor-pointer"
          } `}
          onClick={() => onChange(section)}
        >
          {section}
        </p>
      ))}
    </div>
  );
};
export default Header;
