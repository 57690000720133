import { useEffect, useState } from "react";
import Input from "./Input";
import {
  useEmailValidation,
  useNameInput,
  usePassValidation,
} from "../../hooks";
import { emailRegex } from "../../utils/regEx";
import { useStateContext } from "../../context/CreateContext";
import { Link, useNavigate } from "react-router-dom";
import TypeUser from "./TypeUser";
import axios from "../../api/axios";

const Form = () => {
  const navigate = useNavigate();
  const { Login } = useStateContext();
  const [email, validEmail, setvalidEmail, setEmail] = useEmailValidation();
  const [firstName, firstNameValid, setFirstNameValid, handleFirstName] =
    useNameInput();
  const [lastName, lastNameValid, setLastNameValid, handleLastName] =
    useNameInput();
  const [password, passwordValid, setPasswordValid, handlePassword] =
    usePassValidation();
  const [passMatch, setPassMatch] = useState("");
  const [passMatchValid, setPassMatchValid] = useState(true);
  const [missMatch, setMissMatch] = useState("");
  const [typeUser, setTypeUser] = useState("");
  const [typeUserValid, setTypeUserValid] = useState(true);
  const [emailError, setEmailError] = useState("");
  useEffect(() => {
    if (passMatch) {
      if (passMatch === password) {
        setPassMatchValid(true);
        setMissMatch("");
      } else {
        setPassMatchValid(false);
        setMissMatch("Mismatching Password");
      }
    } else {
      setPassMatchValid(true);
      setMissMatch("");
    }
  }, [passMatch, password]);

  const handleSave = () => {
    if (!password) {
      setPasswordValid(false);
    }
    if (!passMatch) {
      setPassMatchValid(false);
    }
    if (password && passMatch && passwordValid) {
    }
  };

  const HandleLogin = async () => {
    if (!email) {
      setvalidEmail(false);
    }
    if (!password) {
      setPasswordValid(false);
    }
    if (!passMatch) {
      setPassMatchValid(false);
    }
    if (!firstName) {
      setFirstNameValid(false);
    }
    if (!lastName) {
      setLastNameValid(false);
    }
    if (!typeUser) {
      setTypeUserValid(false);
    }

    if (
      password &&
      passMatch &&
      passwordValid &&
      email &&
      validEmail &&
      firstName &&
      lastName &&
      firstNameValid &&
      lastNameValid &&
      typeUser
    ) {
      createAccount();
    }
  };
  const createAccount = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("firstName", firstName);
    bodyFormData.append("lastName", lastName);
    bodyFormData.append("email", email);
    bodyFormData.append("password", password);
    bodyFormData.append("role", typeUser);
    // file && bodyFormData.append("image", file, file?.name);

    try {
      const res = await axios.post(`/auth/signup`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(res);
      navigate("/login");
    } catch (err) {
      const { email } = err?.response?.data;
      setvalidEmail(email ? false : true);
      setEmailError(email ? email : "");
      console.log(err);
    }
  };
  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await HandleLogin();
    }
  };

  return (
    <div className="px-4 pt-4 pb-6 lg:px-0 lg:py-0 rounded-[10px] w-full flex flex-col gap-4">
      <div className="flex flex-col gap-6 relative">
        <Input
          title={"First Name"}
          value={firstName}
          onChange={handleFirstName}
          isValid={firstNameValid}
          setValid={setFirstNameValid}
        />
        <Input
          title={"Last Name"}
          value={lastName}
          onChange={handleLastName}
          isValid={lastNameValid}
        />
        <Input
          title={"Email"}
          placeholder={"Enter Your E-mail"}
          onChange={setEmail}
          value={email}
          isValid={validEmail}
          type={"text"}
          errorMessage={emailError}
          onKeyDown={handleKeyPress}
        />
        <Input
          title={"Password"}
          value={password}
          onChange={handlePassword}
          isValid={passwordValid}
          type={"password"}
        />
        <Input
          title={"Confirm Password"}
          value={passMatch}
          onChange={setPassMatch}
          isValid={passMatchValid}
          type={"password"}
          errorMessage={missMatch}
        />
        <TypeUser
          isValid={typeUserValid}
          value={typeUser}
          onChange={setTypeUser}
          onValid={setTypeUserValid}
        />
      </div>

      <LoginButton onLogin={HandleLogin} />
    </div>
  );
};
export default Form;

const LoginButton = ({ onLogin, handleKeyPress }) => {
  return (
    <button
      className="w-full h-[50px] rounded-xl border bg-[#0455BA]  lg:border-white text-white text-[22px] font-semibold mt-5"
      onClick={onLogin}
      id="Log In"
    >
      Sign Up
    </button>
  );
};
const ErrorMessage = ({ title, errorMessage, style }) => {
  return (
    <div className={`flex items-center mt-1 gap-2 absolute ${style}`}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
          fill="#DD0303"
        />

        <g mask="url(#mask0_972_11061)"></g>
      </svg>
      <p className="text-[12px] text-[#DD0303] font-semibold">
        {errorMessage ? errorMessage : `Please, Enter a valid ${title}`}
      </p>
    </div>
  );
};
