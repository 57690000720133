import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { handleDate } from "../../utils/main";
import ErrorMessage from "../ErrorMessage";
import dayjs from "dayjs";
const DatePicker = ({
  value,
  onChange,
  isValid,
  disblePast,
  title,
  onValid,
}) => {
  const HandleChangeDate = (date) => {
    onChange(handleDate(date));
    onValid(true);
  };
  console.log(value);
  return (
    <div>
      <p className="font-semibold text-[#374151]">{title}</p>
      <Data
        isValid={isValid}
        title={title}
        value={value}
        onDate={HandleChangeDate}
        disblePast={disblePast}
      />
    </div>
  );
};

export default DatePicker;

const Data = ({ onDate, disblePast, isValid, title, value }) => {
  return (
    <div className="w-full h-full relative">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={["DateTimePicker", "DateTimePicker", "DateTimePicker"]}
        >
          {value ? (
            <DateTimePicker
              views={["year", "month", "day"]}
              className="bg-[#fff]"
              onChange={(date) => onDate?.(date)}
              format="YYYY-MM-DD"
              value={value && dayjs(value)}
              disablePast={disblePast}
              // sx={{ border: "2px solid #000", borderRadius: "6px" }}
            />
          ) : (
            <DateTimePicker
              views={["year", "month", "day"]}
              className="bg-[#fff]"
              onChange={(date) => onDate?.(date)}
              format="YYYY-MM-DD"
              disablePast={disblePast}
            />
          )}
        </DemoContainer>
      </LocalizationProvider>
      <ErrorMessage isValid={isValid} title={title} />
    </div>
  );
};
