import React from "react";
import { Link } from "react-router-dom";
import {
  convertDecimalToTime,
  detailsEsam,
  formatTime,
} from "../../utils/main";
import { IoMenu } from "react-icons/io5";

const ExsamCard = ({ title, exsam, examId }) => {
  return (
    <Link to={`exsam/${examId}`}>
      <div className="w-full bg-white shadow-md lg:h-[320px] h-[60px] py-4 px-4 flex flex-col lg:justify-between lg:items-start justify-center rounded-[10px]">
        <Header />
        <DeatilsPhone detailsEsam={detailsEsam} />
        {detailsEsam?.map((exs) => (
          <ExsamDetails
            key={exs.title}
            title={exs.title}
            value={exs.value}
            exsam={exsam}
          />
        ))}
        <Button title={"Start Exam"} />
      </div>
    </Link>
  );
};

export default ExsamCard;

const Header = () => {
  return (
    <div className="lg:flex justify-center w-full hidden">
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58.2 13.3875C57.2869 12.7018 56.1397 12.4051 55.0086 12.5619C53.8776 12.7188 52.8545 13.3167 52.1625 14.225L42.5 27.5V3.75C42.5 2.75544 42.1049 1.80161 41.4016 1.09835C40.6984 0.395088 39.7446 0 38.75 0L3.75 0C2.75544 0 1.80161 0.395088 1.09835 1.09835C0.395088 1.80161 0 2.75544 0 3.75L0 56.25C0 57.2446 0.395088 58.1984 1.09835 58.9016C1.80161 59.6049 2.75544 60 3.75 60H38.75C39.7446 60 40.6984 59.6049 41.4016 58.9016C42.1049 58.1984 42.5 57.2446 42.5 56.25V42.5C42.5056 42.4669 42.5056 42.4331 42.5 42.4L59.25 19.4C59.9057 18.4627 60.1633 17.3038 59.9665 16.1769C59.7697 15.0501 59.1345 14.0471 58.2 13.3875ZM34.85 42.2125L37.8875 44.4375L34.7625 45.525L34.85 42.2125ZM39.375 42.4125L36.325 40.1875L50.85 20.2875L53.9 22.5L39.375 42.4125ZM40 56.25C40 56.5815 39.8683 56.8995 39.6339 57.1339C39.3995 57.3683 39.0815 57.5 38.75 57.5H3.75C3.41848 57.5 3.10054 57.3683 2.86612 57.1339C2.6317 56.8995 2.5 56.5815 2.5 56.25V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5H38.75C39.0815 2.5 39.3995 2.6317 39.6339 2.86612C39.8683 3.10054 40 3.41848 40 3.75V30.9125L33.575 39.725L32.6 41.0625C32.4521 41.2666 32.3693 41.5106 32.3625 41.7625L32.2375 47.275C32.233 47.4764 32.2772 47.676 32.3665 47.8566C32.4557 48.0373 32.5873 48.1937 32.75 48.3125C32.9645 48.4678 33.2227 48.5509 33.4875 48.55C33.6282 48.5484 33.7677 48.523 33.9 48.475L39.1 46.675C39.3392 46.5922 39.5452 46.4344 39.6875 46.225L40 45.8125V56.25ZM57.2375 17.875L55.3625 20.4375L52.3125 18.2125L54.1875 15.7125C54.5035 15.3694 54.9347 15.1545 55.3989 15.1088C55.8632 15.0631 56.328 15.1897 56.7049 15.4647C57.0817 15.7396 57.3443 16.1435 57.4425 16.5996C57.5407 17.0556 57.4678 17.5318 57.2375 17.9375V17.875Z"
          fill="#0455BA"
        />
        <path
          d="M6.25 8.75H36.25C36.5815 8.75 36.8995 8.6183 37.1339 8.38388C37.3683 8.14946 37.5 7.83152 37.5 7.5C37.5 7.16848 37.3683 6.85054 37.1339 6.61612C36.8995 6.3817 36.5815 6.25 36.25 6.25H6.25C5.91848 6.25 5.60054 6.3817 5.36612 6.61612C5.1317 6.85054 5 7.16848 5 7.5C5 7.83152 5.1317 8.14946 5.36612 8.38388C5.60054 8.6183 5.91848 8.75 6.25 8.75Z"
          fill="#0455BA"
        />
        <path
          d="M36.25 21.25H16.25C15.9185 21.25 15.6005 21.3817 15.3661 21.6161C15.1317 21.8505 15 22.1685 15 22.5C15 22.8315 15.1317 23.1495 15.3661 23.3839C15.6005 23.6183 15.9185 23.75 16.25 23.75H36.25C36.5815 23.75 36.8995 23.6183 37.1339 23.3839C37.3683 23.1495 37.5 22.8315 37.5 22.5C37.5 22.1685 37.3683 21.8505 37.1339 21.6161C36.8995 21.3817 36.5815 21.25 36.25 21.25Z"
          fill="#0455BA"
        />
        <path
          d="M34.5875 31.25H16.25C15.9185 31.25 15.6005 31.3817 15.3661 31.6161C15.1317 31.8505 15 32.1685 15 32.5C15 32.8315 15.1317 33.1495 15.3661 33.3839C15.6005 33.6183 15.9185 33.75 16.25 33.75H34.5875C34.919 33.75 35.237 33.6183 35.4714 33.3839C35.7058 33.1495 35.8375 32.8315 35.8375 32.5C35.8375 32.1685 35.7058 31.8505 35.4714 31.6161C35.237 31.3817 34.919 31.25 34.5875 31.25Z"
          fill="#0455BA"
        />
        <path
          d="M28.75 41.25H16.25C15.9185 41.25 15.6005 41.3817 15.3661 41.6161C15.1317 41.8505 15 42.1685 15 42.5C15 42.8315 15.1317 43.1495 15.3661 43.3839C15.6005 43.6183 15.9185 43.75 16.25 43.75H28.75C29.0815 43.75 29.3995 43.6183 29.6339 43.3839C29.8683 43.1495 30 42.8315 30 42.5C30 42.1685 29.8683 41.8505 29.6339 41.6161C29.3995 41.3817 29.0815 41.25 28.75 41.25Z"
          fill="#0455BA"
        />
        <path
          d="M30 12.5C30 12.1685 29.8683 11.8505 29.6339 11.6161C29.3995 11.3817 29.0815 11.25 28.75 11.25H12.5C12.1685 11.25 11.8505 11.3817 11.6161 11.6161C11.3817 11.8505 11.25 12.1685 11.25 12.5C11.25 12.8315 11.3817 13.1495 11.6161 13.3839C11.8505 13.6183 12.1685 13.75 12.5 13.75H28.75C29.0815 13.75 29.3995 13.6183 29.6339 13.3839C29.8683 13.1495 30 12.8315 30 12.5Z"
          fill="#0455BA"
        />
        <path
          d="M28.75 51.25H12.5C12.1685 51.25 11.8505 51.3817 11.6161 51.6161C11.3817 51.8505 11.25 52.1685 11.25 52.5C11.25 52.8315 11.3817 53.1495 11.6161 53.3839C11.8505 53.6183 12.1685 53.75 12.5 53.75H28.75C29.0815 53.75 29.3995 53.6183 29.6339 53.3839C29.8683 53.1495 30 52.8315 30 52.5C30 52.1685 29.8683 51.8505 29.6339 51.6161C29.3995 51.3817 29.0815 51.25 28.75 51.25Z"
          fill="#0455BA"
        />
        <path
          d="M6.25 26.25H11.25C11.5815 26.25 11.8995 26.1183 12.1339 25.8839C12.3683 25.6495 12.5 25.3315 12.5 25V20C12.5 19.6685 12.3683 19.3505 12.1339 19.1161C11.8995 18.8817 11.5815 18.75 11.25 18.75H6.25C5.91848 18.75 5.60054 18.8817 5.36612 19.1161C5.1317 19.3505 5 19.6685 5 20V25C5 25.3315 5.1317 25.6495 5.36612 25.8839C5.60054 26.1183 5.91848 26.25 6.25 26.25ZM7.5 21.25H10V23.75H7.5V21.25Z"
          fill="#0455BA"
        />
        <path
          d="M6.25 36.25H11.25C11.5815 36.25 11.8995 36.1183 12.1339 35.8839C12.3683 35.6495 12.5 35.3315 12.5 35V30C12.5 29.6685 12.3683 29.3505 12.1339 29.1161C11.8995 28.8817 11.5815 28.75 11.25 28.75H6.25C5.91848 28.75 5.60054 28.8817 5.36612 29.1161C5.1317 29.3505 5 29.6685 5 30V35C5 35.3315 5.1317 35.6495 5.36612 35.8839C5.60054 36.1183 5.91848 36.25 6.25 36.25ZM7.5 31.25H10V33.75H7.5V31.25Z"
          fill="#0455BA"
        />
        <path
          d="M12.5 45V40C12.5 39.6685 12.3683 39.3505 12.1339 39.1161C11.8995 38.8817 11.5815 38.75 11.25 38.75H6.25C5.91848 38.75 5.60054 38.8817 5.36612 39.1161C5.1317 39.3505 5 39.6685 5 40V45C5 45.3315 5.1317 45.6495 5.36612 45.8839C5.60054 46.1183 5.91848 46.25 6.25 46.25H11.25C11.5815 46.25 11.8995 46.1183 12.1339 45.8839C12.3683 45.6495 12.5 45.3315 12.5 45ZM10 43.75H7.5V41.25H10V43.75Z"
          fill="#0455BA"
        />
      </svg>
    </div>
  );
};

const ExsamDetails = ({ title, value, exsam }) => {
  return (
    <div className="lg:flex items justify-between w-full px-1 hidden">
      <p className="text-sm text-[#0455BA] font-semibold">{title}:</p>
      <p className="text-xs text-[#374151] truncate  overflow-hidden w-[40%] text-end">
        {" "}
        {title === "Exsam Date"
          ? exsam[value]?.split("T")[0]
          : title === "Exsam Time"
          ? formatTime(exsam[value] ? exsam[value] : null)
          : title === "Exsam Duration"
          ? convertDecimalToTime(exsam[value])
          : exsam[value]}
      </p>
    </div>
  );
};

const Button = ({ title }) => {
  return (
    <button
      className={`bg-[#0455BA] rounded-md lg:flex items-center h-[32px] w-full px-4 gap-2 justify-center text-[12px] hidden`}
    >
      <h3 className="text-[#F5F5F5]  cursor-pointer text-[12px]">{title}</h3>
    </button>
  );
};

const DeatilsPhone = ({ detailsEsam }) => {
  return (
    <div className="lg:text-[#374151] text-[#0455BA] flex items-center justify-between lg:hidden  w-full h-full pr-5">
      <IoMenu
        className="w-[24px] h-[24px] cursor-pointer text-[#0455BA]"
        // onClick={() => onActive?.(true)}
      />
      {/* {detailsEsam?.map((exsam) => ( */}
      <div className="flex items-center gap-4">
        <div className="flex flex-col gap-1">
          <p className="text-sm text-[#0455BA] font-semibold">Title</p>
          <p className="text-sm text-[#0455BA] font-semibold">Date</p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm text-[#374151]">{"Arabic"}</p>
          <p className="text-sm text-[#374151]">17/11/2023</p>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div className="flex flex-col gap-1">
          <p className="text-sm text-[#0455BA] font-semibold">Duration</p>
          <p className="text-sm text-[#0455BA] font-semibold">Date</p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm text-[#374151]">47 Mins</p>
          <p className="text-sm text-[#374151]">3PM</p>
        </div>
      </div>

      {/* ))} */}
    </div>
  );
};
