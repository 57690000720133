import React, { useState } from "react";
import Overview from "../Overview";
import OverviewCard from "../OverviewCard";
import facebook from "../../assets/facebook.png";
import linkedin from "../../assets/linkedin.png";
import instagram from "../../assets/instagram.png";
import twitter from "../../assets/twitter.png";
import messenger from "../../assets/messenger.png";
import { RiLinkM } from "react-icons/ri";
import { IoCopy } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
const ShareLink = ({ onShow }) => {
  return (
    <Overview>
      <OverviewCard
        title={"Share a Link"}
        onShow={onShow}
        width={"lg:max-w-[688px] w-[90%] h-fit"}>
        <Container onShow={onShow} />
      </OverviewCard>
    </Overview>
  );
};

export default ShareLink;

const Container = ({ onShow }) => {
  return (
    <>
      <div className="px-5 mt-5">
        <h2 className="text-lg font-semibold text-[#3F3F3F]">
          Share this Link Via
        </h2>
        {/* <SocialIcon /> */}
        <CopyLink />
      </div>
      <Button onCancle={onShow} />
    </>
  );
};

// const SocialIcon = () => {
//   return (
//     <div className="flex items-center gap-5 mt-5">
//       <img
//         src={facebook}
//         alt=""
//         className="w-[40px] h-[40px] rounded-full object-cover"
//       />
//       <img
//         src={linkedin}
//         alt=""
//         className="w-[40px] h-[40px] rounded-full "
//       />
//       <img
//         src={instagram}
//         alt=""
//         className="w-[40px] h-[40px] rounded-full"
//       />
//       <img
//         src={twitter}
//         alt=""
//         className="w-[40px] h-[40px] rounded-full"
//       />
//       <img
//         src={messenger}
//         alt=""
//         className="w-[40px] h-[40px] rounded-full"
//       />
//     </div>
//   );
// };

const CopyLink = () => {
  const url = window.location.href;

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(url).then(() => {
        toast.success(`The link has been copied`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          closeButton: false,
        });
        console.log("Copy successful");
      });
    } catch (err) {
      console.error("Unable to copy text", err);
    }
  };

  return (
    <div className="mt-5">
      <h2 className="font-semibold text-[#3F3F3F] text-[22px]">Copy Link</h2>
      <div className="w-full lg:h-[70px] h-[50px] border rounded-lg border-[#374151] flex items-center justify-between px-4 mt-3">
        <div className="flex items-center gap-2 w-[93%]">
          <a
            href={url}
            target="_blank">
            <RiLinkM className="w-[18px] h-[18px]" />
          </a>

          <p className="text-[#121212] truncate  overflow-hidden ">{url}</p>
        </div>

        <IoCopy
          className="text-[#0455BA] w-[18px] h-[18px] cursor-pointer"
          onClick={handleCopyClick}
          id="copyButton"
        />
      </div>
    </div>
  );
};

const Button = ({ onCancle }) => {
  return (
    <div className="border-t px-5 border-[#9CA3AF] mt-5 flex items-center justify-end">
      <button
        className="w-[150px]  h-[50px] rounded-md border-[#374151] border font-semibold text-[#374151] mt-5"
        onClick={() => onCancle?.(false)}>
        Cancel
      </button>
    </div>
  );
};
