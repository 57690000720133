import React from "react";
import Overview from "../../components/Overview";
import AddExsamForm from "../../components/home/AddExsam";
import OverviewCard from "../../components/OverviewCard";

const AddExsam = ({
  onShow,
  title,
  startDate,
  description,
  duration,
  publicEx,
  examId,
  onChangeData,
}) => {
  return (
    <Overview>
      <OverviewCard title={"New Exam"} onShow={onShow}>
        <AddExsamForm
          onShow={onShow}
          Title={title}
          StartDate={startDate}
          Description={description}
          Duration={duration}
          examId={examId}
          publicEx={publicEx}
          onChangeData={onChangeData}
        />
      </OverviewCard>
    </Overview>
  );
};

export default AddExsam;
