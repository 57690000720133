import React, { useEffect, useState } from "react";
import Button from "../addForm/Button";
import Input from "../addForm/Input";
import DatePicker from "../addForm/DatePicker";
import TimeInput from "../addForm/TimePicker";
import TypeExsam from "../addForm/TypeExsam";
import {
  useDescValidation,
  usePriceValidation,
  useTitleValidation,
} from "../../hooks";
import axios from "../../api/axios";
import {
  convertDecimalToTime,
  convertTimeToDecimal,
  handleTime,
} from "../../utils/main";
import useTime from "../../hooks/useTime";
const AddExsamForm = ({
  onShow,
  handleSubmit,
  isLoading,
  Title,
  Description,
  StartDate,
  Duration,
  publicEx,
  examId,
  onChangeData,
}) => {
  useEffect(() => {
    const dateSplit = StartDate?.split("T");
    handleTitle(Title);
    handleDescription(Description);
    dateSplit && setStartTime(StartDate);
    dateSplit && setExsamDate(dateSplit[0]);
    Duration && handleDuration(convertDecimalToTime(Duration));
    publicEx && setTypeExsam(publicEx ? "Public" : "Private");
  }, [Duration, StartDate, Description, Title, publicEx, examId]);
  console.log(publicEx);
  const [title, titleValid, setTitleValid, handleTitle] = useTitleValidation();
  const [duration, DurationValid, setDurationValid, handleDuration] = useTime();
  const [
    description,
    descriptionValid,
    setDescriptionValid,
    handleDescription,
  ] = useTitleValidation();
  const [exsamDate, setExsamDate] = useState("");
  const [exsamDateValid, setExsamDateValid] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [startTimeValid, setStartTimeValid] = useState(true);
  const [typeExsam, setTypeExsam] = useState("");
  const [typeExsamValid, setTypeExsamValid] = useState(true);
  const handleSave = () => {
    if (!title) {
      setTitleValid(false);
    }
    if (!description) {
      setDescriptionValid(false);
    }
    if (!exsamDate) {
      setExsamDateValid(false);
    }
    if (!startTime) {
      setStartTimeValid(false);
    }
    if (!duration) {
      setDurationValid(false);
    }

    if (!typeExsam) {
      setTypeExsamValid(false);
    }

    // If all fields are valid, proceed with form submission logic
    if (
      descriptionValid &&
      titleValid &&
      title &&
      descriptionValid &&
      startTime &&
      exsamDate &&
      typeExsam
    ) {
      examId ? putData() : postData();
    }
  };

  const HandleCancle = () => {
    onShow(false);
  };

  const startDate = exsamDate + "T" + handleTime(startTime);
  console.log(startDate);
  const postData = async () => {
    try {
      const response = await axios.post("/teacher-api/exams", {
        title: title,
        description: description,
        startDate,
        duration: convertTimeToDecimal(duration),
        isPublic: typeExsam === "Public" ? true : false,
      });
      onShow(false);
      console.log("Response:", response.data);
      onChangeData?.((e) => !e);
      // Handle success or further actions here
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
    }
  };
  const putData = async () => {
    try {
      const response = await axios.put(`/teacher-api/exams/${examId}`, {
        title: title,
        description: description,
        startDate,
        duration: convertTimeToDecimal(duration),
        isPublic: typeExsam === "Public" ? true : false,
      });
      onShow(false);
      console.log("Response:", response.data);
      onChangeData?.((e) => !e);
      // Handle success or further actions here
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
    }
  };
  console.log(startTime);
  return (
    <>
      <div className="flex flex-col gap-8 px-5 bg-[#E5E7EB] lg:bg-white rounded-lg py-5 lg:py-0">
        <Input
          title={"Exam title"}
          placeHolder={"Enter your title"}
          value={title}
          onChange={handleTitle}
          valid={titleValid}
        />
        <Input
          placeHolder={"Description about your course..."}
          title={"Describtion"}
          heigth={"h-[83px]"}
          value={description}
          onChange={handleDescription}
          valid={descriptionValid}
        />
        <DatePicker
          value={exsamDate}
          onChange={setExsamDate}
          isValid={exsamDateValid}
          disblePast={true}
          title={"Exam Date"}
          onValid={setExsamDateValid}
        />
        <TimeInput
          value={startTime}
          onChange={setStartTime}
          isValid={startTimeValid}
          disblePast={true}
          title={"Start Time"}
          onValid={setStartTimeValid}
        />
        <Input
          title={"Exam Duration"}
          placeHolder={"hh:mm"}
          value={duration}
          onChange={handleDuration}
          valid={DurationValid}
          errorMessage={
            "Please, Enter a valid Exam Duration must format (hh:mm)"
          }
        />
        <TypeExsam
          isValid={typeExsamValid}
          value={typeExsam}
          onChange={setTypeExsam}
          onValid={setTypeExsamValid}
        />
      </div>
      <Button
        onSave={handleSave}
        onCancle={HandleCancle}
        title={"save"}
        isLoading={isLoading}
      />
    </>
  );
};

export default AddExsamForm;
