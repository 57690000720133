import React from "react";
const Submissions = ({
  headerList,
  dataList,
  children,
  length,
  filteShow,
  submissionsLength,
}) => {
  return (
    <div>
      <h2 className="text-[24px] font-semibold text-[#0455BA] mt-2">
        {filteShow ? submissionsLength : length} Exams
      </h2>
      <Header headerList={headerList} />
      {children}
    </div>
  );
};

export default Submissions;

const Header = ({ headerList }) => {
  return (
    <div className="relative h-[60px] lg:flex items-center w-[100%] bg-[#0455BA] text-[#fff] rounded-xl font-semibold text-xl mt-2 hidden">
      {headerList?.map((header) => (
        <div key={header.title}>
          <p
            className={`text-[20px] font-semibold absolute ${header.postion} top-[30%]`}
          >
            {header.title}
          </p>
        </div>
      ))}
    </div>
  );
};
