import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import Submissions from "../../components/Submissions";
import {
  handelFilter,
  handleFilteData,
  handleFilteSubmition,
  histoyrHeaderList,
  histoyrList,
} from "../../utils/main";
import HistoryCard from "../../components/HistoryCard";
import PaginationRounded from "../../components/Paginate";
import { getData } from "../../api/request";
import FeedbackExsam from "../../components/takeExsam/FeedbackExsam";

const History = () => {
  const [submissions, setSubmissions] = useState([]);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterData, setFilterData] = useState(false);
  const [query, setQuery] = useState("");
  const [submissionsFilter, setSubmissionsFilter] = useState([]);
  const [filteShow, setfilteShow] = useState(false);
  const [feedback, setFeedback] = useState(false);

  useEffect(() => {
    getData(`/student-api/submissions`, setSubmissions);
  }, []);
  useEffect(() => {
    const filterData = handleFilteData(
      submissions,
      startDate,
      endDate,
      setfilteShow,
      setSubmissionsFilter,
      query,
      "examTitle"
    );
    setSubmissionsFilter(filterData);
  }, [query, filterData]);
  const { numberOfPages, dataForPage } = handelFilter(
    submissions,
    submissionsFilter,
    filteShow,
    page,
    7
  );
  return (
    <>
      {feedback ? (
        <FeedbackExsam
          submissionsId={feedback}
          // title={title}
          onFeedback={setFeedback}
          // studentName={studentName}
          role={"student"}
        />
      ) : (
        <div className="max-h-screen">
          <SearchBar
            placeHolder={"Search By Exam Title"}
            onEndDate={setEndDate}
            onStartData={setStartDate}
            onFilterData={setFilterData}
            startDate={startDate}
            endDate={endDate}
            onSearch={setQuery}
          />
          <Submissions
            headerList={histoyrHeaderList}
            dataList={histoyrList}
            length={submissions?.length}
            submissionsLength={submissionsFilter?.length}
            filteShow={filteShow}>
            <HistorysContainer
              dataList={histoyrList}
              submissions={dataForPage}
              numberOfPages={numberOfPages}
              onPage={setPage}
              onFeedback={setFeedback}
            />
          </Submissions>
        </div>
      )}
    </>
  );
};

export default History;
const HistorysContainer = ({
  dataList,
  submissions,
  numberOfPages,
  onPage,
  onFeedback,
}) => {
  return (
    <div className="mt-3 h-full lg:min-h-[645px] min-h-[710px] max-h-[710px] flex flex-col justify-between">
      <div className="flex flex-col lg:gap-5 gap-8 mt-3">
        {submissions.length > 0 &&
          submissions.map((submission) => (
            <HistoryCard
              data={dataList}
              submission={submission}
              onFeedback={onFeedback}
            />
          ))}
      </div>
      {submissions.length > 0 && (
        <PaginationRounded
          count={numberOfPages}
          setPage={onPage}
        />
      )}
    </div>
  );
};
