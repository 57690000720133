import AddExsam from "../containers/home/AddExsam";
import DeleteMessage from "./DeleteMessage";

const OverviewDeleteAndEdit = ({
  deleteMessage,
  onCancle,
  onEdit,
  editCard,
  title,
  startDate,
  description,
  duration,
  publicEx,
  examId,
  onChangeData,
  HandleDelete,
}) => {
  const handleDelete = async (e) => {
    try {
      const response = await HandleDelete();
      console.log(response);
      onCancle(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {deleteMessage && (
        <DeleteMessage
          title={`Are you sure about to delete this Exsam`}
          bref={"If you delete those questions  you them again"}
          nameButton={"Yes"}
          onCancle={onCancle}
          onDelete={handleDelete}
        />
      )}
      {editCard && (
        <AddExsam
          onShow={onEdit}
          title={title}
          startDate={startDate}
          description={description}
          duration={duration}
          examId={examId}
          publicEx={publicEx}
          onChangeData={onChangeData}
        />
      )}
    </>
  );
};
export default OverviewDeleteAndEdit;
