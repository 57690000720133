import React from "react";
import Overview from "../../components/Overview";
import OverviewCard from "../../components/OverviewCard";
import AddQuestionsForm from "../../components/exsamManage/AddQuestionsForm";

const AddQuestion = ({
  title,
  onShow,
  onChangeData,
  id,
  questioniId,
  questionsNumber,
  editCard,
  TextTitle,
  TitleText,
  answerText,
  similarityText,
  choicesMCQ,
  typeQuetion,
  questionScore,
}) => {
  console.log(
    TextTitle,
    TitleText,
    answerText,
    similarityText,
    choicesMCQ,
    typeQuetion,
    "hsjafdhkjsdfkhj"
  );
  return (
    <Overview>
      <OverviewCard
        title={editCard ? "Edit Question" : title}
        onShow={onShow}
        styles={"lg:max-h-[960px]"}>
        <AddQuestionsForm
          questionScoreOld={questionScore}
          onShow={onShow}
          onChangeData={onChangeData}
          id={id}
          questioniId={questioniId}
          questionsNumber={questionsNumber}
          editCard={editCard}
          TextTitle={TextTitle}
          TitleText={TitleText}
          answerText={answerText}
          similarityText={similarityText}
          choicesMCQ={choicesMCQ}
          typeQuetion={typeQuetion}
        />
      </OverviewCard>
    </Overview>
  );
};

export default AddQuestion;
