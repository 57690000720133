import { useState } from "react";
import Input from "./Input";
import { useEmailValidation } from "../../hooks";
import { emailRegex } from "../../utils/regEx";
import { useStateContext } from "../../context/CreateContext";
import { Link, useNavigate } from "react-router-dom";

const Form = () => {
  const navigate = useNavigate();
  const { Login } = useStateContext();
  const [email, validEmail, setvalidEmail, setEmail] = useEmailValidation();
  const [password, setPassword] = useState("");
  const [valid, setvalid] = useState(true);
  const [validMessage, setvalidMessage] = useState("");
  const [validPassowrd, setValidPassowrd] = useState(true);
  const [remember, setRemember] = useState(false);
  const HandleLogin = async () => {
    if (email && password) {
      try {
        const req = await Login({ email, password }, remember);
        const [successfully, role] = req ? req : null;

        if (req && successfully) {
          navigate("/", { replace: true });
        } else if (role[0] === "ROLE_NOT_VERIFIED_TEACHER") {
          setvalidEmail(false);
          setValidPassowrd(false);
          setvalid(false);
          setvalidMessage("Please verify your email and try again");
        }
      } catch (err) {
        setvalidEmail(false);
        setValidPassowrd(false);
        setvalid(false);
        setvalidMessage("Falid Pass or email");
      }
    }

    if (!email && !password) {
      setvalidMessage("Please Enter Your Email and Password");
      console.log("test");
      setvalidEmail(false);
      setValidPassowrd(false);
      setvalid(false);
    }

    if (email) {
      if (!emailRegex.test(email)) {
        setvalidMessage("Please enter the correct email format");
        setvalidEmail(false);
        setValidPassowrd(true);
        setvalid(false);
      }
    }

    if (!email && password) {
      setvalidMessage("Please Enter your email");
      setvalidEmail(false);
      setValidPassowrd(true);
      setvalid(false);
    }
    if (email && !password) {
      setvalidMessage("Please Enter a password");
      setvalidEmail(true);
      setValidPassowrd(false);
      setvalid(false);
    }
    // if (!emailRegex.test(email) && !password) {
    //   setvalid(false);
    //   setvalidMessage("Please Enter a password & correct email format");
    // }
  };
  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await HandleLogin();
    }
  };

  return (
    <div className="px-4 pt-4 pb-6 lg:px-0 lg:py-0 rounded-[10px] w-full flex flex-col gap-4">
      <div className="flex flex-col gap-5 relative">
        <Input
          title={"E-mail"}
          placeholder={"Enter Your E-mail"}
          onChange={setEmail}
          value={email}
          isValid={validEmail}
          type={"text"}
          onKeyDown={handleKeyPress}
        />

        <Input
          title={"password"}
          placeholder={"Enter Your Password"}
          value={password}
          onChange={setPassword}
          isValid={validPassowrd}
          type={"password"}
          onKeyDown={handleKeyPress}
        />
        {!valid && (
          <ErrorMessage style={"bottom-[-15%]"} errorMessage={validMessage} />
        )}
      </div>

      <ForgetPass
        //   setRestPassword={setRestPassword}
        onRemember={setRemember}
      />
      <LoginButton onLogin={HandleLogin} />
    </div>
  );
};
export default Form;

const ForgetPass = ({ setRestPassword, onRemember }) => {
  return (
    <div className="flex items-center justify-between w-full mt-6">
      <label class="custom-checkbox__login flex items-center gap-3 text-sm  font-semibold">
        <input
          type="checkbox"
          onChange={(e) => onRemember?.(e.target.checked)}
        />
        <span className="checkmark__login bg-[#fff]  border-[2px] border-[#0D1C2E]   w-[18px]  h-[18px]"></span>
        Remember me
      </label>

      <div>
        <p
          className=" cursor-pointer text-[#0455BA]  text-sm  font-semibold underline hover:text-[#1004ba] transition-all duration-100"
          onClick={() => setRestPassword?.(true)}
        >
          Forgot Password?
        </p>
      </div>
    </div>
  );
};
const LoginButton = ({ onLogin, handleKeyPress }) => {
  return (
    <button
      className="w-full h-[50px] rounded-xl border bg-[#0455BA]  lg:border-white text-white text-[22px] font-semibold"
      onClick={onLogin}
      id="Log In"
    >
      Log In
    </button>
  );
};
const ErrorMessage = ({ title, errorMessage, style }) => {
  return (
    <div className={`flex items-center mt-1 gap-2 absolute ${style}`}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
          fill="#DD0303"
        />

        <g mask="url(#mask0_972_11061)"></g>
      </svg>
      <p className="text-[12px] text-[#DD0303] font-semibold">
        {errorMessage ? errorMessage : `Please, Enter a valid ${title}`}
      </p>
    </div>
  );
};
