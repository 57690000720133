const Button = ({
  onSave,
  onCancel,
  fristButton,
  secondButton,
  width,
  fristIcon,
}) => {
  return (
    <div
      className={`flex items-center gap-4 lg:flex-row-reverse flex-col ${
        width ? width : " w-fit"
      }`}
    >
      {fristButton && (
        <button
          className={` ${
            width ? width : "w-full lg:w-[150px]"
          } h-[42px] lg:h-[56px] bg-[#0455BA] rounded-[6px] text-white text-lg flex items-center gap-2 justify-center`}
          onClick={() => onSave?.()}
        >
          {fristIcon}
          {fristButton}
        </button>
      )}

      {secondButton && (
        <button
          className="w-full  h-[42px] lg:w-[150px] lg:h-[56px] border text-black rounded-[6px] border-[#9CA3AF] text-lg "
          onClick={() => onCancel?.(true)}
        >
          {secondButton}
        </button>
      )}
    </div>
  );
};
export default Button;
