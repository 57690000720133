import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import OverviewDeleteAndEdit from "./OverviewDeleteAndEdit";
import ButtonsDeleteAndEdit from "./ButtonsDeleteAndEdit";
import { IoMenu } from "react-icons/io5";
import { HiDotsVertical } from "react-icons/hi";
import { format, parseISO } from "date-fns";
import { formatTime } from "../utils/main";
import axios from "../api/axios";

const Exsam = ({
  title,
  startDate,
  description,
  duration,
  examId,
  publicEx,
  onChangeData,
}) => {
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [editExsam, setEditExsam] = useState(false);
  const [cardActive, setCardActive] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        // Clicked outside the card, so close it
        setCardActive(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const HandleDelete = async (e) => {
    try {
      const response = await axios.delete(`/teacher-api/exams/${examId}`);
      console.log(response);
      onChangeData((e) => !e);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <OverviewDeleteAndEdit
        deleteMessage={deleteMessage}
        onCancle={setDeleteMessage}
        editCard={editExsam}
        onEdit={setEditExsam}
        title={title}
        startDate={startDate}
        description={description}
        duration={duration}
        examId={examId}
        publicEx={publicEx}
        onChangeData={onChangeData}
        HandleDelete={HandleDelete}
      />
      <Link to={`exsam/${examId}`} ref={cardRef} key={examId}>
        <div className="flex items-center justify-between  h-[65px] border-[#374151] card__lessons  cursor-pointer lg:bg-[#E5E7EB] bg-white shadow-md px-3 rounded-xl">
          <DeatilsPhone />
          <div className="hidden lg:block">
            <Title title={title} />
          </div>

          <div className="lg:hidden relative">
            <HiDotsVertical
              className="w-[24px] h-[24px] cursor-pointer text-[#0455BA] "
              onClick={(e) => {
                setCardActive((e) => !e);
                e.preventDefault();
              }}
            />
            <ManageCard
              onDelete={setDeleteMessage}
              onEdit={setEditExsam}
              cardActive={cardActive}
              onActive={setCardActive}
            />
          </div>
          <div className="flex items-center gap-[150px]">
            <div className="hidden lg:block">
              <Date startDate={startDate} />
            </div>
            <ButtonsDeleteAndEdit
              onDelete={setDeleteMessage}
              onEdit={setEditExsam}
            />
          </div>
        </div>
      </Link>
    </>
  );
};

export default Exsam;

const Title = ({ title }) => {
  return <h2 className="lg:text-2xl font-semibold ">{title}</h2>;
};

const Date = ({ startDate }) => {
  const dateSplit = startDate?.split("T");
  console.log(dateSplit);

  return (
    <div className="flex items-center gap-4 lg:text-xl text-xs font-semibold">
      {dateSplit && (
        <>
          <h2>
            Date:{" "}
            <span className="lg:text-base text-xs font-normal">
              {dateSplit[0]}
            </span>
          </h2>
          <h2>
            at:{" "}
            <span className="lg:text-base text-xs font-normal">
              {formatTime(startDate)}
            </span>
          </h2>
        </>
      )}
    </div>
  );
};
const DeatilsPhone = () => {
  return (
    <div className="lg:text-[#374151] text-[#0455BA] flex items-center gap-4 lg:hidden">
      <IoMenu
        className="w-[24px] h-[24px] cursor-pointer text-[#0455BA]"
        // onClick={() => onActive?.(true)}
      />
      <div>
        <Title title={"Arabic"} />
        <Date />
      </div>
    </div>
  );
};

const ManageCard = ({ onDelete, onEdit, cardActive, onActive }) => {
  return (
    <>
      {cardActive && (
        <div className="flex items-center justify-between gap-4 w-[159px] min-h-[70px] bg-[#E5E7EB] rounded-lg flex-col py-4 px-3 absolute top-6 right-0 z-10">
          <Buttons onDelete={onDelete} onEdit={onEdit} onActive={onActive} />
        </div>
      )}
    </>
  );
};
const Buttons = ({ onEdit, onDelete, onActive }) => {
  const handelEdit = (e) => {
    e.preventDefault();
    onEdit?.(true);
    onActive?.(false);
  };
  const handelDelete = (e) => {
    e.preventDefault();
    onDelete?.(true);
    onActive?.(false);
  };

  return (
    <div className="flex items-start gap-4 flex-col w-full">
      <button
        className=" rounded-md flex items-center justify-between w-full  gap-2 text-[#1F2937] font-bold text-xs"
        onClick={(e) => {
          handelEdit?.(e);
        }}
      >
        Edit
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
            fill="#40A726"
          />

          <g mask="url(#mask0_1461_1482)"></g>
        </svg>
      </button>

      <div
        className="rounded-md flex items-center justify-between w-full  gap-2 text-[#1F2937] font-bold text-xs"
        onClick={(e) => {
          handelDelete?.(e);
        }}
      >
        Delete
        <svg
          width="20"
          height="20"
          viewBox="0 0 34 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.334 9.00016H29.7507C30.5298 9.00016 31.1673 9.63766 31.1673 10.4168C31.1673 11.196 30.5298 11.8335 29.7507 11.8335H28.334V27.4168C28.334 29.76 26.4272 31.6668 24.084 31.6668H9.91732C7.57415 31.6668 5.66732 29.76 5.66732 27.4168V11.8335H4.25065C3.47148 11.8335 2.83398 11.196 2.83398 10.4168C2.83398 9.63766 3.47148 9.00016 4.25065 9.00016H5.66732H11.334V6.6315C11.334 4.8125 12.9221 3.3335 14.8757 3.3335H19.1257C21.0792 3.3335 22.6673 4.8125 22.6673 6.6315V9.00016H28.334ZM14.8756 6.16677C14.4704 6.16677 14.1673 6.41186 14.1673 6.63144V9.00011H19.8339V6.63144C19.8339 6.41186 19.5308 6.16677 19.1256 6.16677H14.8756ZM21.2505 24.5834C22.0296 24.5834 22.6671 23.9459 22.6671 23.1668V17.5001C22.6671 16.7209 22.0296 16.0834 21.2505 16.0834C20.4713 16.0834 19.8338 16.7209 19.8338 17.5001V23.1668C19.8338 23.9459 20.4713 24.5834 21.2505 24.5834ZM14.1678 23.1668C14.1678 23.9459 13.5303 24.5834 12.7511 24.5834C11.9719 24.5834 11.3344 23.9459 11.3344 23.1668V17.5001C11.3344 16.7209 11.9719 16.0834 12.7511 16.0834C13.5303 16.0834 14.1678 16.7209 14.1678 17.5001V23.1668Z"
            fill="#DD0303"
          />

          <g mask="url(#mask0_771_8630)"></g>
        </svg>
      </div>
    </div>
  );
};
