import React from "react";
import ExsamCard from "../../components/homeStudent/ExsamCard";

const ExsamGrid = ({ exsams }) => {
  return (
    <div className="gridCourses lg:grid grid-rows-2 gap-5 mt-5 flex flex-col">
      {exsams &&
        exsams.map((exsam) => (
          <ExsamCard
            // title={exsam?.title}
            // key={exsam.examId}
            // startDate={exsam?.startDate}
            // duration={exsam?.duration}
            // description={exsam?.description}
            // publicEx={exsam?.public}
            exsam={exsam}
            examId={exsam?.examId}
            // onChangeData={onChangeData}
          />
        ))}

      {/* <ExsamCard />
      <ExsamCard />
      <ExsamCard />
      <ExsamCard />
      <ExsamCard />
      <ExsamCard />
      <ExsamCard />
      <ExsamCard />
      <ExsamCard /> */}
    </div>
  );
};

export default ExsamGrid;
