import React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Radios from "../Radios";
import ErrorMessage from "../ErrorMessage";
const TypeUser = ({ isValid, onChange, value, onValid }) => {
  const handleChange = (e) => {
    onChange(e);
    onValid(true);
  };
  return (
    <div className="flex items-center gap-5 relative justify-between">
      <FormControl className="flex items-center justify-between w-full">
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          className="flex items-center gap-[4rem] w-full">
          <p className="text-lg font-semibold">Sign up As :</p>
          <Radios
            value={"ROLE_TEACHER"}
            label={"Teacher"}
            checked={value === "ROLE_TEACHER"}
            onChange={handleChange}
          />
          <Radios
            value={"ROLE_STUDENT"}
            label={"Student"}
            checked={value === "ROLE_STUDENT"}
            onChange={handleChange}
          />
        </RadioGroup>
      </FormControl>
      <ErrorMessage
        isValid={isValid}
        title={"Type of Exam"}
        style={"top-[75%]"}
      />
    </div>
  );
};

export default TypeUser;
