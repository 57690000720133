import React, { useState } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Radios from "../Radios";
const QuestionRedios = ({ listRadios, bg, isCorrect, correct, onChecked }) => {
  // const [correct, setCorrect] = useState("q1");
  console.log(listRadios);
  return (
    <div className="flex items-center mr-10">
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          onChange={(e) => onChecked?.(e.target.value)}
        >
          {listRadios &&
            listRadios.map((radio) => (
              <div className="flex items-center">
                <Radios
                  value={radio?.choiceText}
                  label={radio?.choiceText}
                  bg={radio.correct ? bg : "#DD0303"}
                  checked={radio.correct}
                />
                {isCorrect && (
                  <Correct correct={radio.correct} radio={radio?.choiceTex} />
                )}
              </div>
            ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default QuestionRedios;

const Correct = ({ correct, radio }) => {
  return (
    <>{correct && <p className="text-[#40A726] font-semibold">Correct</p>}</>
  );
};
