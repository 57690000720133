import { useParams } from "react-router-dom";
import axios from "../../api/axios";
import AddQuestion from "../../containers/exsamManage/AddQuestion";
import DeleteMessage from "../DeleteMessage";

const QuestionDAndE = ({
  deleteMessage,
  onCancle,
  onEdit,
  editCard,
  title,
  answer,
  similarity,
  questioniId,
  onChangeData,
  typeQuetion,
  choices,
  questionNo,
  text,
  tit,
  questionScore,
}) => {
  const { id } = useParams();

  console.log(choices, "fiwhuehiufewhuiehfuwiihuewfhuiefwiuhhiufew");
  const HandleDelete = async (e) => {
    try {
      const response = await axios.delete(
        `teacher-api/exams/${id}/questions/${questioniId}`
      );
      console.log(response);
      onCancle(false);
      onChangeData?.((e) => !e);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(similarity, "fwieooiwojifjiwoe");
  return (
    <>
      {deleteMessage && (
        <DeleteMessage
          title={"Are you sure about to delete this  Question"}
          bref={"If you delete those questions  you them again"}
          nameButton={"Yes"}
          onCancle={onCancle}
          onDelete={HandleDelete}
        />
      )}

      {editCard && (
        <AddQuestion
          questionScore={questionScore}
          MCQTitle={title}
          onShow={onEdit}
          onChangeData={onChangeData}
          id={id}
          questioniId={questioniId}
          questionsNumber={questionNo}
          editCard={editCard}
          TextTitle={tit}
          TitleText={text}
          answerText={answer}
          similarityText={similarity}
          typeQuetion={typeQuetion}
          choicesMCQ={choices}
        />
      )}
    </>
  );
};
export default QuestionDAndE;
