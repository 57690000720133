import React, { useEffect, useState } from "react";
import Button from "../Button";
import QuestionRedios from "../exsamManage/QuestionRedios";
import { FaCheck } from "react-icons/fa6";
import { IoArrowBackCircleOutline, IoClose } from "react-icons/io5";
import { LuArrowDownToLine } from "react-icons/lu";
import { useParams } from "react-router-dom";
import { getData } from "../../api/request";
import Radios from "../Radios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const FeedbackExsam = ({
  submissionsId,
  title,
  onFeedback,
  studentName,
  feedbackExsam,
  role,
}) => {
  const { id } = useParams();
  const [feedback, setFeedback] = useState([]);
  useEffect(() => {
    submissionsId && role === "student"
      ? getData(`/student-api/submissions/${submissionsId}`, setFeedback)
      : getData(
          `/teacher-api/exams/${id}/submissions/${submissionsId}`,
          setFeedback
        );
  }, [submissionsId, id]);

  return (
    <div className="mt-20">
      <Header
        title={title}
        score={feedback?.score}
        onFeedback={onFeedback}
        studentName={studentName}
      />
      <Answer answers={feedback?.answers} />
    </div>
  );
};

export default FeedbackExsam;

const Header = ({ title, score, onFeedback, studentName }) => {
  const generatePDF = async () => {
    const pdf = new jsPDF();
    let currentY = 15; // Initial y-coordinate

    // Set text color to #0455BA (blue) for the header
    pdf.setTextColor(4, 85, 186);
    // Set font style for the header
    pdf.setFont("helvetica", "bold");

    try {
      const capture = document.querySelector("#feedback");
      const chartImage = await html2canvas(capture);

      // Calculate the scaling factor to maintain the aspect ratio
      const scaleFactor = pdf.internal.pageSize.width / chartImage.width;
      const scaledWidth = pdf.internal.pageSize.width;
      const scaledHeight = chartImage.height * scaleFactor;

      // Add centered text at the top of the page (Header)
      pdf.text(
        `Feedback ${title} Exam`,
        pdf.internal.pageSize.width / 2,
        currentY,
        {
          align: "center",
        }
      );

      // Set text color to #0455BA (blue) for the "Score" label
      pdf.setTextColor(4, 85, 186);
      // Add text for the "Score" label
      pdf.text(`Score: `, pdf.internal.pageSize.width / 2 - 20, currentY + 10);

      // Set text color to black for the score value
      pdf.setTextColor(0, 0, 0);
      // Add text for the score value
      pdf.text(
        `(${score}%)`,
        pdf.internal.pageSize.width / 2 + 0, // Adjusted position
        currentY + 10
      );

      // Set text color to #0455BA (blue) for the "Name" label
      pdf.setTextColor(4, 85, 186);
      // Add text for the "Name" label
      pdf.text(`Name: `, pdf.internal.pageSize.width / 2 - 20, currentY + 20); // Adjusted position

      // Set text color to black for the student's name
      pdf.setTextColor(0, 0, 0);
      // Add text for the student's name
      pdf.text(
        `(${studentName})`,
        pdf.internal.pageSize.width / 2 + 0, // Adjusted position
        currentY + 20
      );

      // Increase the y-coordinate for the next element
      currentY += 40; // You can adjust this value based on your preference

      // Add padding on the X-axis (horizontal padding)
      const horizontalPadding = 10; // Adjust the padding value as needed
      const xCoordinate =
        (pdf.internal.pageSize.width - scaledWidth) / 2 + horizontalPadding;

      // Add the chart image to the PDF, centered with X-axis padding
      pdf.addImage(
        chartImage.toDataURL("image/png"),
        "PNG",
        xCoordinate,
        currentY,
        scaledWidth - 2 * horizontalPadding, // Adjust the width with padding
        scaledHeight
      );

      // Save the current y-coordinate for potential further content
      currentY += scaledHeight;
    } catch (err) {
      console.log(err);
    }

    // Save the PDF
    pdf.save(`${title}(${studentName}).pdf`);
  };

  return (
    <div className="text-[#0455BA] flex items-center gap-3 lg:border-b-2 border-[#9CA3AF]  pb-5 justify-between flex-col w-full lg:flex-row">
      <div className="flex items-center gap-2">
        <IoArrowBackCircleOutline
          className="w-[40px] h-[40px] cursor-pointer"
          onClick={() => onFeedback?.(false)}
        />
        <h2 className="font-semibold text-[28px] lg:border-none border-b-2 border-[#9CA3AF] w-full pb-5 lg:pb-0">
          Feedback {title} Exam ({studentName})
        </h2>
      </div>

      <Button
        fristButton={"Download"}
        width={"lg:w-[250px] w-full mt-5 lg:mt-0"}
        onSave={() => generatePDF(true)}
        fristIcon={<LuArrowDownToLine className="w-[25px] h-[25px]" />}
      />
    </div>
  );
};

const Answer = ({ answers }) => {
  const questionsText = answers?.filter(
    (question) => question.questionType === "TEXT_BASED"
  );
  const questionsMCQ = answers?.filter(
    (question) => question.questionType === "MCQ"
  );

  return (
    <div
      className="pb-5"
      id="feedback">
      {questionsText?.length > 0 &&
        questionsText?.map((question, i) => (
          <QuestionText
            modelAnswer={question?.modelAnswer}
            studentAnswer={question?.studentAnswer}
            correct={question?.correct}
            questionslength={answers.length}
            i={i}
            text={question?.text}
          />
        ))}
      {questionsMCQ?.length > 0 &&
        questionsMCQ?.map((question, i) => (
          <QuestionMcq
            modelAnswer={question?.modelAnswer}
            text={question?.text}
            studentAnswer={question?.studentAnswer}
            correct={question?.correct}
            questionslength={answers.length}
            i={i + questionsText.length}
            choices={question.choices}
          />
        ))}
    </div>
  );
};

const QuestionMcq = ({
  modelAnswer,
  studentAnswer,
  correct,
  questionslength,
  i,
  choices,
  text,
}) => {
  return (
    <div className="mt-5 border-b-[3px] border-[#0455BA] pb-2">
      <p className="text-[#1F2937] font-semibold ">
        Answer {i + 1} of {questionslength}
      </p>
      <div className="lg:ml-5 mt-5  border-[#9CA3AF] pb-2 lg:pl-3">
        <p className="text-[#1F2937] font-semibold pb-2">{text}</p>
        <div className="flex flex-col gap-2">
          {choices &&
            choices.map((radio, i) => (
              <div className="flex items-center gap-4 mt-2">
                <input
                  type="checkbox"
                  className={`${
                    radio?.correct === true && radio?.chosen
                      ? "correct"
                      : "worng"
                  } 
                  
                  `}
                  id={radio.choiceNo}
                  name="fav_language"
                  value={radio?.choiceText}
                  checked={radio?.chosen}
                />
                <label
                  htmlFor="html"
                  className={`${radio?.correct && "text-[#40A726]"} ${
                    radio?.correct === false &&
                    radio?.chosen &&
                    "text-[#DD0303]"
                  } font-semibold`}>
                  {radio?.choiceText}
                </label>{" "}
                <Correct
                  correct={radio?.correct}
                  worng={radio?.chosen}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const QuestionText = ({
  modelAnswer,
  studentAnswer,
  correct,
  questionslength,
  i,
  text,
}) => {
  return (
    <div className="mt-5 border-b-[3px] border-[#0455BA] pb-2">
      <p className="text-[#1F2937] font-semibold ">
        Answer {i + 1} of {questionslength}
      </p>
      <div className="lg:ml-5 mt-5 border-b border-[#9CA3AF] pb-2 lg:pl-3">
        <p className="text-[#1F2937] font-semibold pb-2">{text}</p>
        <div
          className={`${
            correct ? "text-[#40A726]" : "text-[#DD0303] "
          } font-semibold flex items-start gap-5`}>
          <div>
            {correct ? (
              <FaCheck className="w-[24px] h-[24px] " />
            ) : (
              <IoClose className="w-[26px] h-[26px] " />
            )}
          </div>
          <p> {studentAnswer}</p>
        </div>
      </div>
      <div className="lg:ml-5 mt-5 pb-2 lg:pl-3">
        <p className="text-[#0455BA] font-semibold pb-2">Correct Answer</p>
        <div className="text-[#40A726] font-semibold flex items-start gap-5">
          <div>
            <FaCheck className="w-[24px] h-[24px] " />
          </div>
          <p className="font-semibold">{modelAnswer}</p>
        </div>
      </div>
    </div>
  );
};
const Correct = ({ correct, radio, worng }) => {
  return (
    <>
      {correct ? (
        <p className="text-[#40A726] font-semibold">Correct</p>
      ) : (
        worng && <p className="text-[#DD0303] font-semibold">wrong</p>
      )}
    </>
  );
};
