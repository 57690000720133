const ButtonsDeleteAndEdit = ({ onEdit, onDelete, showMobile }) => {
  return (
    <div
      className={`flex items-center lg:justify-end gap-4 ${
        showMobile ? "flex-col w-full lg:flex-row" : "hidden lg:flex"
      } lg:w-[30%] w-full`}
    >
      <button
        className="lg:max-w-[120px] w-full h-[44px] rounded-md border px-2 border-[#40A726] flex items-center justify-center gap-1 font-semibold text-lg text-[#40A726]"
        onClick={(e) => {
          onEdit?.(true);
          e.preventDefault();
        }}
      >
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
            fill="#40A726"
          />

          <g mask="url(#mask0_1461_1482)"></g>
        </svg>
        Edit
      </button>

      <button
        className="lg:max-w-[150px] w-full rounded-md h-[44px] px-2 bg-[#DD0303] flex items-center justify-center text-lg text-white font-semibold gap-1"
        onClick={(e) => {
          onDelete?.(true);
          e.preventDefault();
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.666 3.49999H12.2493C12.5702 3.49999 12.8327 3.76249 12.8327 4.08332C12.8327 4.40416 12.5702 4.66666 12.2493 4.66666H11.666V11.0833C11.666 12.0482 10.8808 12.8333 9.91602 12.8333H4.08268C3.11785 12.8333 2.33268 12.0482 2.33268 11.0833V4.66666H1.74935C1.42852 4.66666 1.16602 4.40416 1.16602 4.08332C1.16602 3.76249 1.42852 3.49999 1.74935 3.49999H2.33268H4.66602V2.52466C4.66602 1.77566 5.31993 1.16666 6.12435 1.16666H7.87435C8.67877 1.16666 9.33268 1.77566 9.33268 2.52466V3.49999H11.666ZM6.12435 2.33332C5.95752 2.33332 5.83268 2.43424 5.83268 2.52466V3.49999H8.16602V2.52466C8.16602 2.43424 8.04118 2.33332 7.87435 2.33332H6.12435ZM8.74935 9.91666C9.07018 9.91666 9.33268 9.65416 9.33268 9.33332V6.99999C9.33268 6.67916 9.07018 6.41666 8.74935 6.41666C8.42852 6.41666 8.16602 6.67916 8.16602 6.99999V9.33332C8.16602 9.65416 8.42852 9.91666 8.74935 9.91666ZM5.83268 9.33332C5.83268 9.65416 5.57018 9.91666 5.24935 9.91666C4.92852 9.91666 4.66602 9.65416 4.66602 9.33332V6.99999C4.66602 6.67916 4.92852 6.41666 5.24935 6.41666C5.57018 6.41666 5.83268 6.67916 5.83268 6.99999V9.33332Z"
            fill="white"
          />
        </svg>
        Delete
      </button>
    </div>
  );
};

export default ButtonsDeleteAndEdit;
