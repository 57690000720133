import React from "react";
import Button from "../Button";

const ResultExsam = ({ onFeedback, feedbackExsam, duration, exsam }) => {
  return (
    <div className="mt-10 flex justify-center flex-col items-center">
      <Header title={exsam?.title} />
      <Score
        onFeedback={onFeedback}
        score={feedbackExsam?.score}
        length={feedbackExsam?.answers?.length}
        answers={feedbackExsam?.answers}
        duration={duration}
      />
    </div>
  );
};

export default ResultExsam;

const Header = ({ title }) => {
  return (
    <div className="text-[#0455BA] flex items-center gap-3 border-b-2 border-[#9CA3AF] pb-5 w-full">
      <h2 className="font-semibold text-[28px] text">Result Of {title} Exam</h2>
    </div>
  );
};

const Score = ({ onFeedback, score, length, answers, duration }) => {
  function getPluralScore(data) {
    const scores = data && data.map((item) => item.score);
    const totalScore = score && scores.reduce((acc, score) => acc + score, 0);
    return totalScore;
  }
  const pluralScore = getPluralScore(answers);

  return (
    <div className="flex flex-col gap-5 items-center w-fit mt-20 justify-between">
      <div className="flex items-center gap-10 justify-between w-full">
        <h2 className="font-bold text-[32px] text-[#0455BA]">Score</h2>
        <h2 className="font-bold text-[32px] text-[#374151]">
          {score}/{pluralScore} (
          {((score / pluralScore) * 100).toFixed(1) !== "NaN"
            ? ((score / pluralScore) * 100).toFixed(1)
            : "0"}
          %)
        </h2>
      </div>
      <div className="flex items-center gap-10 justify-between w-full">
        <h2 className="font-bold text-[32px] text-[#0455BA]">Duration</h2>
        <h2 className="font-bold text-[32px] text-[#374151]">{duration()}</h2>
      </div>
      <Button
        fristButton={"Download"}
        width={"w-[100%] w-full"}
        onSave={() => onFeedback(true)}
      />
    </div>
  );
};
