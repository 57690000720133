import React from "react";
import profile from "../../assets/Ellipse 10.png";
import { Link, NavLink } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { RiLogoutBoxFill } from "react-icons/ri";

const SideNav = ({ navList, onActive, activNav }) => {
  return (
    <div
      className={`w-[70%] fixed top-0 ${
        activNav ? "left-0" : "left-[-100%]"
      }  h-full bg-[#F3F4F6] border-[#1f618d] rounded-tr-[20px] rounded-br-[20px] z-20 lg:hidden duration-200 transition-all`}>
      <Header
        name={"Mahmoud Sheta"}
        id={"1"}
        onActive={onActive}
      />
      <NavList
        navList={navList}
        onActive={onActive}
      />
    </div>
  );
};

export default SideNav;

const Header = ({ name, id, onActive }) => {
  return (
    <div className="w-full h-[147px] bg-[#1A2483] flex items-center  justify-between text-2xl text-white px-4 gap-2 lg:hidden">
      <div className="flex items-center gap-5">
        <Link
          to={"profile"}
          id="MyProfile">
          <div className="inline-flex items-center justify-center gap-[10px] relative">
            <img
              src={profile}
              alt={name}
              className="w-[60px] h-[60px] rounded-full"
              id={id}
            />
          </div>
        </Link>
        <div className="text-xl font-semibold">
          <p>{name}</p>
          <p>#{id}</p>
        </div>
      </div>
      <IoArrowBackCircleOutline
        className="w-[46px] h-[46px] cursor-pointer"
        onClick={() => onActive?.(false)}
      />
    </div>
  );
};

const NavList = ({ onActive, navList }) => {
  return (
    <div className="flex flex-col ">
      {navList?.map((nav) => (
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "flex items-center gap-2 bg-[#0455BA]  text-white w-full h-[70px]"
              : "flex items-center gap-2 text-[#1F618D] w-full h-[70px] hover:bg-[#abcadf8c] hover:pl-2 transition-all"
          }
          to={nav.to}
          onClick={() => onActive?.(false)}
          id={nav.title}
          key={nav.title}>
          <div className="flex items-center gap-4 px-5 ">
            <div>{nav.icon}</div>
            <p className="font-semibold">{nav.title}</p>
          </div>
        </NavLink>
      ))}

      <div
        className={
          "flex items-center gap-2 text-[#1F618D] w-full h-[70px] hover:bg-[#abcadf8c] hover:pl-2 transition-all cursor-pointer"
        }
        // onClick={test}
        id={"Log Out"}>
        <div className="flex items-center gap-4 px-5 ">
          <RiLogoutBoxFill className="w-[25px] h-[25px]" />

          <p className="font-semibold">Log Out</p>
        </div>
      </div>
    </div>
  );
};
