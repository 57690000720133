import React, { useEffect, useState } from "react";
import {
  useDescValidation,
  usePriceValidation,
  useSelectValidation,
  useTitleValidation,
} from "../../hooks";
import Button from "../addForm/Button";
import Input from "../addForm/Input";
import Select from "../addForm/Select";
import Radios from "../Radios";
import { RiDeleteBin6Fill } from "react-icons/ri";
import RadioGroup from "@mui/material/RadioGroup";
// import FormControl from "@mui/material/FormControl";
import axios from "../../api/axios";
// import { useParams } from "react-router-dom";
import { priceReg } from "../../utils/regEx";
const AddQuestionsForm = ({
  onShow,
  onChangeData,
  questioniId,
  id,
  editCard,
  questionsNumber,
  TextTitle,
  MCQTitle,
  TitleText,
  answerText,
  similarityText,
  choicesMCQ,
  typeQuetion,
  questionScoreOld,
}) => {
  // const { id } = useParams();

  const [answers, setAnswers] = useState([
    {
      choiceText: "",
      isCorrect: false,
      choiceNo: 1,
    },
  ]);
  const [answersValid, setAnswersValid] = useState("true");
  const [title, titleValid, setTitleValid, handleTitle] = useTitleValidation();
  const [textAnswer, textAnswerValid, setTextAnswerValid, handleTextAnswer] =
    useTitleValidation();
  const [
    similarity,
    similarityValid,
    setSimilarityValid,
    handleSimilarity,
    setSimilarity,
  ] = usePriceValidation();
  const [
    questionScore,
    qusetionScoreValid,
    setQuestionScoreValid,
    handleQuestionScore,
    setQuestionScore,
  ] = usePriceValidation();
  const [
    questionNo,
    questionNoValid,
    setQuestionNoValid,
    handleQuestionNo,
    setQuestionNo,
  ] = usePriceValidation();
  const [
    description,
    descriptionValid,
    setDescriptionValid,
    handleDescription,
    setDescription,
  ] = useTitleValidation();
  const [
    questionType,
    questionTypeValid,
    setQuestionTypeValid,
    handleQuestionType,
  ] = useSelectValidation();
  const validateAndSetState = (value, setValidState) => {
    if (!value) {
      setValidState(false);
    } else {
      setValidState(true);
    }
  };
  useEffect(() => {
    handleQuestionType("MCQ");
  }, []);

  // useEffect(() => {
  //   if (editCard) {
  //     handleQuestionType(typeQuetion);
  //     if (typeQuetion === "MCQ") {
  //       handleTitle(MCQTitle);
  //       handleDescription(TitleText);
  //       setQuestionScore(questionScoreOld);
  //       setQuestionNo(questionsNumber);
  //       setAnswers(choicesMCQ);
  //     } else {
  //       handleTitle(TextTitle);
  //       setSimilarity(similarityText);
  //       setQuestionScore(questionScoreOld);
  //       setQuestionNo(questionsNumber);
  //       handleDescription(TitleText);
  //       handleTextAnswer(answerText);
  //     }
  //     // Set the initial state for each input field based on the passed props
  //   }
  // }, [
  //   editCard,
  //   MCQTitle,
  //   TextTitle,
  //   TitleText,
  //   answerText,
  //   questionScoreOld,
  //   similarityText,
  //   choicesMCQ,
  //   questionsNumber,
  //   typeQuetion,
  // ]);
  useEffect(() => {
    if (editCard) {
      handleQuestionType(typeQuetion);
      if (typeQuetion === "MCQ") {
        handleTitle(MCQTitle);
        handleDescription(TitleText);
        handleQuestionScore(questionScoreOld.toString());
        setQuestionScoreValid(true);

        handleQuestionNo(questionsNumber.toString());
        setQuestionNoValid(true);

        setAnswers(choicesMCQ);
      } else {
        handleTitle(TextTitle);
        handleDescription(TitleText);
        handleTextAnswer(answerText);

        handleSimilarity(similarityText.toString()); // Validate and set similarity
        setSimilarityValid(
          similarityText !== undefined && similarityText !== null
        );

        handleQuestionScore(questionScoreOld.toString());
        setQuestionScoreValid(true);

        handleQuestionNo(questionsNumber.toString());
        setQuestionNoValid(true);
      }
    }
  }, [
    editCard,
    typeQuetion,
    MCQTitle,
    TextTitle,
    TitleText,
    answerText,
    questionScoreOld,
    similarityText,
    choicesMCQ,
    questionsNumber,
  ]);

  function hasCorrectChoice(choices) {
    for (let i = 0; i < choices.length; i++) {
      if (choices[i].correct) {
        return true;
      }
    }
    return false;
  }
  function anyAnswerHasEmptyText(choices) {
    for (let i = 0; i < choices.length; i++) {
      if (choices[i].choiceText.trim() === "") {
        return true; // At least one answer has empty choiceText
      }
    }
    return false; // All answers have non-empty choiceText
  }
  const handleSave = () => {
    validateAndSetState(title, setTitleValid);
    validateAndSetState(textAnswer, setTextAnswerValid);
    validateAndSetState(description, setDescriptionValid);
    validateAndSetState(questionType, setQuestionTypeValid);
    validateAndSetState(similarity, setSimilarityValid);
    if (!questionNo) {
      setQuestionNoValid(false);
    }
    // If all fields are valid, proceed with form submission logic
    if (!questionScore) {
      setQuestionScoreValid(false);
    }

    if (answers.length <= 1 || answers.length === 1) {
      setAnswersValid("There should be at least two answers");
    }

    if (answers.length > 1) {
      if (anyAnswerHasEmptyText(answers)) {
        // setAnswersValid(false);
        setAnswersValid("all answers must have non-empty value");
      }
      // if (hasCorrectChoice(answers) && !anyAnswerHasEmptyText(answers)) {
      //   setAnswersValid(true);
      // }
      if (!hasCorrectChoice(answers)) {
        setAnswersValid("No Correct Answer Selected");
      }
    }
    if (
      answers.length >= 2 &&
      hasCorrectChoice(answers) &&
      !anyAnswerHasEmptyText(answers)
    ) {
      setAnswersValid("true");
    }

    if (questionType === "TEXT_BASED") {
      if (descriptionValid && titleValid && questionType) {
        if (editCard) {
          putData();
        } else {
          postData();
        }
      }
    }
    if (questionType === "MCQ") {
      if (
        answers.length > 1 &&
        descriptionValid &&
        questionType &&
        answersValid
      ) {
        if (editCard && questioniId) {
          putData();
        } else {
          postData();
        }
      }
    }
  };

  const handleSaveAnthoer = () => {
    validateAndSetState(title, setTitleValid);
    validateAndSetState(textAnswer, setTextAnswerValid);
    validateAndSetState(description, setDescriptionValid);
    validateAndSetState(questionType, setQuestionTypeValid);
    validateAndSetState(similarity, setSimilarityValid);
    validateAndSetState(questionScore, setQuestionScoreValid);

    if (!questionNo) {
      setQuestionNoValid(false);
    }
    // If all fields are valid, proceed with form submission logic

    // if (answers.length <= 2) {
    //   setAnswersValid(false);
    // }
    if (answers.length <= 1 || answers.length === 1) {
      setAnswersValid("There should be at least two answers");
    }

    if (answers.length > 1) {
      if (anyAnswerHasEmptyText(answers)) {
        // setAnswersValid(false);
        setAnswersValid("all answers must have non-empty value");
      }
      // if (hasCorrectChoice(answers) && !anyAnswerHasEmptyText(answers)) {
      //   setAnswersValid(true);
      // }
      if (!hasCorrectChoice(answers)) {
        setAnswersValid("No Correct Answer Selected");
      }
    }
    if (
      answers.length >= 2 &&
      hasCorrectChoice(answers) &&
      !anyAnswerHasEmptyText(answers)
    ) {
      setAnswersValid("true");
    }

    if (questionType === "TEXT_BASED") {
      if (descriptionValid && titleValid && questionType) {
        if (editCard && questioniId) {
          putDataAnother();
        } else {
          postDataAnother();
        }
        setQuestionScore("");
        setSimilarity("");
        // setTitle("");
        setDescription("");
        setQuestionNo("");
      }
    }
    if (questionType === "MCQ") {
      if (
        answers.length > 1 &&
        descriptionValid &&
        questionType &&
        answersValid
      ) {
        if (editCard && questioniId) {
          putDataAnother();
        } else {
          postDataAnother();
        }
        setQuestionScore("");
        setSimilarity("");
        // setTitle("");
        setDescription("");
        setQuestionNo("");
        setAnswers([{ choiceText: "", isCorrect: false, choiceNo: 1 }]);
      }
    }
  };

  const HandleCancle = () => {
    onShow(false);
  };
  const postData = async () => {
    const questionText = {
      text: description,
      questionType: questionType,
      similarity: similarity,
      modelAnswer: textAnswer,
      score: Number(questionScore),
      questionNo: questionNo ? Number(questionNo) : 0,
    };

    const questionMCQ = {
      text: description,
      questionType: questionType,
      score: Number(questionScore),
      choices: answers,
      questionNo: questionNo ? Number(questionNo) : 0,
    };
    console.log(questionMCQ, "the quest data is");

    const data = questionType === "TEXT_BASED" ? questionText : questionMCQ;
    console.log(data);
    try {
      const response = await axios.post(
        `teacher-api/exams/${id}/questions`,
        questionType === "TEXT_BASED" ? questionText : questionMCQ
      );

      onShow(false);
      console.log("Response:", response.data);

      // Assuming onChangeData is a function that takes a boolean as an argument
      onChangeData((prevData) => !prevData);
      // Handle success or further actions here
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
    }
  };

  const postDataAnother = async () => {
    const questionText = {
      text: description,
      questionType: questionType,
      similarity: similarity,
      modelAnswer: textAnswer,
      score: Number(questionScore),
      questionNo: questionNo ? Number(questionNo) : 0,
    };
    const questionMCQ = {
      text: description,
      questionType: questionType,
      score: Number(questionScore),
      choices: answers,
      questionNo: questionNo ? Number(questionNo) : 0,
    };

    try {
      const response = await axios.post(
        `teacher-api/exams/${id}/questions`,
        questionType === "TEXT_BASED" ? questionText : questionMCQ
      );

      console.log("Response:", response.data);
      handleTitle("");
      handleSimilarity("");
      handleQuestionNo();
      setQuestionNoValid(true);
      setAnswers([
        {
          choiceText: "",
          isCorrect: false,
          choiceNo: 1,
        },
      ]);
      handleDescription("");
      onChangeData((prevData) => !prevData);
      // Handle success or further actions here
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
    }
  };

  const putData = async () => {
    const questionText = {
      text: description,
      questionType: questionType,
      similarity: similarity,
      modelAnswer: textAnswer,
      score: Number(questionScore),
      questionNo: questionNo ? Number(questionNo) : 0,
    };
    const questionMCQ = {
      text: description,
      questionType: questionType,
      score: Number(questionScore),
      questionNo: questionNo ? Number(questionNo) : 0,
      modelAnswer: "TEST",
      choices: answers,
    };

    console.log(questionMCQ, "the quest data is");

    try {
      const response = await axios.put(
        `teacher-api/exams/${id}/questions/${questioniId}`,
        questionType === "TEXT_BASED" ? questionText : questionMCQ
      );

      onShow(false);
      console.log("Response:", response.data);
      // Assuming onChangeData is a function that takes a boolean as an argument
      onChangeData((prevData) => !prevData);
      // Handle success or further actions here
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
    }
  };
  const putDataAnother = async () => {
    const questionText = {
      text: description,
      questionType: questionType,
      similarity: similarity,
      modelAnswer: textAnswer,
      score: Number(questionScore),
      questionNo: questionNo ? questionNo : 0,
    };
    const questionMCQ = {
      text: description,
      questionType: questionType,
      score: Number(questionScore),
      questionNo: questionNo ? questionNo : 0,
      modelAnswer: "TEST",
      choices: answers,
    };

    try {
      const response = await axios.put(
        `teacher-api/exams/${id}/questions/${questioniId}`,
        questionType === "TEXT_BASED" ? questionText : questionMCQ
      );

      handleTitle("");
      handleSimilarity("");
      handleQuestionNo();
      setQuestionNoValid(true);
      setAnswers([
        {
          choiceText: "",
          isCorrect: false,
          choiceNo: 1,
        },
      ]);
      handleDescription("");
      console.log("Response:", response.data);
      // Assuming onChangeData is a function that takes a boolean as an argument
      onChangeData((prevData) => !prevData);
      // Handle success or further actions here
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
    }
  };

  // Assuming onShow and onChangeData are state-modifying functions, make sure they are defined and used correctly.
  const handleSimilatity = (e) => {
    if (Number(e) <= 100) {
      handleSimilarity?.(e);
    } else {
      if (!priceReg.test(similarity)) {
        setSimilarityValid(false);
      }
    }
  };
  return (
    <>
      <div className="flex flex-col lg:gap-11 gap-5 px-5 mt-5 bg-[#E5E7EB] lg:bg-white rounded-lg py-5 lg:py-0">
        <Select
          title={"Question type"}
          valid={questionTypeValid}
          value={questionType}
          onChange={handleQuestionType}
          similarity={similarity}
          onSimilarity={handleSimilarity}
          setSimilarityValid={setSimilarityValid}
          similarityValid={similarityValid}
        />
        {questionType === "TEXT_BASED" && (
          <>
            <Input
              title={"Similatity"}
              placeHolder={"Enter your similarity (0% - 100%)"}
              value={similarity}
              onChange={handleSimilatity}
              defaultValue={editCard ? similarityText : ""}
              valid={similarityValid}
              errorMessage={"similarity must be [0%-100%]"}
              style={"flex w-full lg:gap-5 flex-col lg:flex-row"}
              inputWidth={"lg:w-[80%] w-full"}
            />
            <Input
              placeHolder={"Enter Question No"}
              title={"Question No"}
              value={questionNo}
              onChange={handleQuestionNo}
              valid={questionNoValid}
              style={"flex w-full lg:gap-5 flex-col lg:flex-row"}
              inputWidth={"lg:w-[80%] w-full"}
            />
            <Input
              placeHolder={"Enter Question Score"}
              title={"Question Score"}
              value={questionScore}
              onChange={handleQuestionScore}
              valid={qusetionScoreValid}
              style={"flex w-full lg:gap-5 flex-col lg:flex-row"}
              inputWidth={"lg:w-[80%] w-full"}
            />
            <Input
              placeHolder={"Enter your question"}
              title={"Question"}
              heigth={"h-[83px]"}
              value={description}
              onChange={handleDescription}
              valid={descriptionValid}
              style={"flex w-full lg:gap-5 flex-col lg:flex-row"}
              inputWidth={"lg:w-[80%] w-full"}
            />
            <div className="lg:border-t lg:pt-5 lg:pb-3 mt-8 border-[#9CA3AF] px-5 bg-[#E5E7EB] lg:bg-white lg:rounded-none rounded-lg py-5 lg:py-0">
              <Input
                title={"Correct Answer"}
                placeHolder={"Enter your answer"}
                value={textAnswer}
                onChange={handleTextAnswer}
                valid={textAnswerValid}
                style={"flex w-full lg:gap-5 flex-col lg:flex-row"}
                inputWidth={"lg:w-[80%] w-full"}
              />
            </div>
          </>
        )}
        {questionType === "MCQ" && (
          <>
            <Input
              placeHolder={"Enter Question No"}
              title={"Question No"}
              value={questionNo}
              onChange={handleQuestionNo}
              valid={questionNoValid}
              style={"flex w-full lg:gap-5 flex-col lg:flex-row"}
              inputWidth={"lg:w-[80%] w-full"}
            />
            <Input
              placeHolder={"Enter Question Score"}
              title={"Question Score"}
              value={questionScore}
              onChange={handleQuestionScore}
              valid={qusetionScoreValid}
              style={"flex w-full lg:gap-5 flex-col lg:flex-row"}
              inputWidth={"lg:w-[80%] w-full"}
            />
            <Input
              placeHolder={"Enter your question"}
              title={"Question"}
              heigth={"h-[83px]"}
              value={description}
              onChange={handleDescription}
              valid={descriptionValid}
              style={"flex w-full lg:gap-5 flex-col lg:flex-row"}
              inputWidth={"lg:w-[80%] w-full"}
            />
            <div className="lg:border-t lg:pt-5 lg:pb-3 mt-8 border-[#9CA3AF] px-5 bg-[#E5E7EB] lg:bg-white lg:rounded-none rounded-lg py-5 lg:py-0">
              <AnswerMcq
                answers={answers}
                setAnswers={setAnswers}
                answersValid={answersValid}
                setAnswersValid={setAnswersValid}
                editCard={editCard}
              />
            </div>
          </>
        )}
      </div>

      <Button
        onSave={handleSave}
        onCancle={HandleCancle}
        addAnother={true}
        onSaveAnother={handleSaveAnthoer}
        editCard={editCard}
      />
    </>
  );
};

export default AddQuestionsForm;

const AnswerMcq = ({
  answers,
  setAnswers,
  answersValid,
  setAnswersValid,
  editCard,
}) => {
  const handleAdd = () => {
    if (answers.length < 5) {
      setAnswers((prevAnswers) => [
        ...prevAnswers,
        { choiceText: "", isCorrect: false, choiceNo: answers.length + 1 },
      ]);
    }
  };

  const handleDelete = (id) => {
    if (answers.length > 1) {
      setAnswers((prevAnswers) => prevAnswers.filter((answer, i) => i !== id));
    }
  };

  const handleInputChange = (index, newValue) => {
    setAnswers((prevAnswers) =>
      prevAnswers?.map((answer, i) =>
        i === index ? { ...answer, choiceText: newValue } : answer
      )
    );
  };

  const handleRadioChange = (index) => {
    console.log(answers);
    setAnswers((prevAnswers) =>
      prevAnswers?.map((answer, i) =>
        i === index
          ? { ...answer, correct: true }
          : { ...answer, correct: false }
      )
    );
  };

  return (
    <div className="flex items-start px-0 gap-5">
      <p className="font-semibold text-[#374151] w-[20%] text-start ">
        Correct&Answer
      </p>
      <div className="w-[80%]">
        <RadioGroup className="relative flex flex-col gap-3">
          {answers.map((answer, i) => (
            <div
              className="flex items-center gap-2 h-full w-full"
              key={i}>
              <div
                className={`${"h-[40px]"} ${
                  answersValid && answersValid !== "true"
                    ? "border-[#DD0303] border-2"
                    : "border-[#374151] "
                }  border rounded-[8px]  relative ${"w-full"} `}>
                <input
                  type="text"
                  className="w-full h-full rounded-[8px] px-2 placeholder:text-[#6B7280] outline-none placeholder:align-text-top placeholder-start"
                  value={answer.choiceText}
                  onChange={(e) => handleInputChange(i, e.target.value)}
                />
              </div>
              <div className="flex items-center">
                <Radios
                  value={i}
                  bg={"#000"}
                  onChange={() => handleRadioChange(i)}
                  checked={answer?.correct}
                />
                <RiDeleteBin6Fill
                  className="w-[32px] h-[32px] text-[#DD0303] cursor-pointer"
                  onClick={() => handleDelete(i)}
                />
              </div>
            </div>
          ))}
          {answersValid && answersValid !== "true" && (
            <ErrorMessage errorMessage={answersValid} />
          )}
          <AddButton
            title={"Add"}
            onAdd={handleAdd}
          />
        </RadioGroup>
      </div>
    </div>
  );
};
const AddButton = ({ onAdd, title }) => {
  return (
    <div className="w-full flex mt-6">
      <button
        className={`bg-[#0455BA] rounded-md flex items-center h-[36px] w-full lg:w-fit  px-4 gap-2 justify-center text-lg font-semibold`}
        onClick={() => onAdd?.()}>
        <div className="bg-[#F5F5F5] rounded-md">
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 6C12.3183 6 12.6235 6.12643 12.8485 6.35147C13.0736 6.57652 13.2 6.88174 13.2 7.2V10.8H16.8C17.1183 10.8 17.4235 10.9264 17.6485 11.1515C17.8736 11.3765 18 11.6817 18 12C18 12.3183 17.8736 12.6235 17.6485 12.8485C17.4235 13.0736 17.1183 13.2 16.8 13.2H13.2V16.8C13.2 17.1183 13.0736 17.4235 12.8485 17.6485C12.6235 17.8736 12.3183 18 12 18C11.6817 18 11.3765 17.8736 11.1515 17.6485C10.9264 17.4235 10.8 17.1183 10.8 16.8V13.2H7.2C6.88174 13.2 6.57652 13.0736 6.35147 12.8485C6.12643 12.6235 6 12.3183 6 12C6 11.6817 6.12643 11.3765 6.35147 11.1515C6.57652 10.9264 6.88174 10.8 7.2 10.8H10.8V7.2C10.8 6.88174 10.9264 6.57652 11.1515 6.35147C11.3765 6.12643 11.6817 6 12 6Z"
              fill="#1E69CB"
            />
          </svg>
        </div>
        <h3 className="text-[#F5F5F5] font-semibold cursor-pointer">{title}</h3>
      </button>
    </div>
  );
};
const ErrorMessage = ({ title, errorMessage }) => {
  return (
    <div className="flex items-center  gap-2 absolute bottom-10">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
          fill="#DD0303"
        />

        <g mask="url(#mask0_972_11061)"></g>
      </svg>
      <p className="text-[12px] text-[#DD0303] font-semibold">
        {errorMessage ? errorMessage : `Please, Enter a valid ${title}`}
      </p>
    </div>
  );
};
